// Drawer.js
import React, { useState } from 'react';
import './shared-components/Drawer.css';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useMount } from 'react-use';
import { XMarkIcon, ClockIcon, MapPinIcon, CalendarIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { useTranslation } from "react-i18next";
import {
  cancellationDrawerOpenAtom,
  notificationsAtom,
  reloadBookingsAtom,
  userAtom,
  selectedBookingPaymentAtom
} from '../../../atoms/Atoms';
import bookingApi from '../../../api/bookingApi';
import Button from '../buttons/Button';
import dayjs from 'dayjs';

const CancelBookingDrawer = ({ isOpen, selectedBooking }) => {
  const { t, i18n } = useTranslation();
  
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const setSelectedBookingPayment = useSetRecoilState(selectedBookingPaymentAtom);
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resultMessage, setResultMessage] = useState(null);
  const user = useRecoilValue(userAtom);
  const [isDrawerOpen, setIsDrawerOpen] = useRecoilState(cancellationDrawerOpenAtom);
  const [reloadBookings, setReloadBookings] = useRecoilState(reloadBookingsAtom);
  const today = dayjs().add(4, 'hour');

  function submit() {
    if (!loading) {
      setLoading(true);
      bookingApi.post(`events/booking/${selectedBooking.id}`, {
        comment: '',
        cancellationFee: 0
      })
        .then((response) => {
        setLoading(false);
        setIsChecked(false);
        if (response.data.success) {
          if (response.data.message) {
            setNotifications([
              ...notifications,
              {
                title: '',
                description: response.data.message,
                error: false,
              },
            ]);
          }
          setIsDrawerOpen(false);
          setReloadBookings(!reloadBookings);
        } else {
          setNotifications([
            ...notifications,
            {
              title: `${t('load_error')}`,
              description: response.data.error,
              error: true,
            },
          ]);

          setIsDrawerOpen(false);
          setReloadBookings(!reloadBookings);
        }
        })
        .catch((err) => {
        // If there is an unhandled exception it is handled here
        setLoading(false);
        setNotifications([
          ...notifications,
          {
            title: `${t('load_error')}`,
            description: err.message,
            error: true,
          },
        ]);
        });
    }
  }

  function handleCheckboxChange() {
    setIsChecked(!isChecked);
  }

  useMount(() => {
    setIsChecked(false);
    setResultMessage(null);
  });

  const checkTimeLeft = () => {
    const timeLeft = 
      dayjs(selectedBooking?.eventTimeSlot?.startTime).diff(today, 'minutes');
    return timeLeft;
  }

  return (
    <div className={`drawer ${isOpen ? 'open' : ''}`}>
      <button 
        className="close-button" 
        onClick={()=> {
          setIsDrawerOpen(false);
          setIsChecked(false);
          setResultMessage('');
          }}>
        <div className="flex flex-row">
          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
          <div className="ml-2">Close</div>
        </div>
      </button>    
      {!user && 
      <div className="mt-24 px-6">Please <a className="text-yellow-500" href="/login">login</a> to drop in</div>}
      {user && 
        <div>
          <div className="drawer-content">
            {/* Your drawer content goes here */}
            <div className="text-yellow-300 text-lg mb-2">Booking Details</div>
            <div className='mb-2'>{selectedBooking && selectedBooking?.event.name}</div>
            {' '}
            <div className='flex flex-row mb-2'>
              <CalendarIcon className="h-6 w-6 mr-4" aria-hidden="true" />
              {`${selectedBooking && moment(selectedBooking?.eventTimeSlot.startTime).format('dddd, MMMM DD, YYYY')}`}
            </div>
            <div className="flex flex-row mb-2">
              <ClockIcon className="h-6 w-6 mr-4" aria-hidden="true" />
              <div>{`${selectedBooking && moment(selectedBooking?.eventTimeSlot.startTime).add(-4, 'hour').format('h:mm A')}`}</div>
            </div>
            {' '}
            {selectedBooking?.event.location && (
              <div className="flex flex-row mb-2">
                <MapPinIcon className="h-6 w-6 mr-4" aria-hidden="true" />
                <div>{selectedBooking && selectedBooking?.event.location?.name}</div>
              </div>
            )}
            {/* <div className="text-yellow-300 mt-4">{`$${selectedBooking && selectedBooking?.event.price.toFixed(2)}`} (one space)</div> */}

            {selectedBooking?.lastPayment && (
              <Button
                text={t('receipt')}
                textColor="text-white"
                disabled={loading}
                onClick={() => {
                  setSelectedBookingPayment(selectedBooking?.lastPayment);
                }}
                loaderColor="bg-white"
                className="w-full text-xl mt-4 mb-8"
              />
            )}

            {checkTimeLeft() > 120 && selectedBooking.status !== 2 &&
              <div className='divide-y divide-solid'>
                <div className="mt-6" />
                <div>
                  <ul className="agreementList">
                    <li>You can cancel your reservation up to 2 hours before the class starts.</li>
                  </ul>
                  <div className="flex items-center mx-1 mt-8 mb-4">
                    <input
                      type="checkbox"
                      className="agreementCheckbox"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />
                    <label htmlFor="agreement" className="acceptance">
                      I have read and accept the terms.
                    </label>
                  </div>
                  <div>
                      <Button
                          text="Cancel"
                          textColor="text-white"
                          disabled={!isChecked || loading}
                          loading={loading}
                          onClick={() => submit()}
                          loaderColor="bg-white"
                          className="mt-12 bg-red-500 hover:bg-red-600 w-1/2 mr-10 ml-4 text-white"
                      />
                  </div>
                </div>
              </div>}
        </div>
      <div className="flex flex-col content-center p-6">{resultMessage}</div>
      </div>}
    </div>
  );
};

export default CancelBookingDrawer;
import React, { useEffect, useRef, useState } from "react";
import _ from 'lodash';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReceipt, faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  reloadSubscriptionsAtom,
  userAtom,
  changeSubscriptionPaymentMethodAtom,
  selectedSubscriptionPaymentAtom
} from "../../../../../atoms/Atoms";
import bookingApi from '../../../../../api/bookingApi';
import {
  useRecoilState,
  useRecoilValue,
  useSetRecoilState
} from "recoil";
import { getRecurrence } from '../../../../../functions';
import Button from "../../../../shared-components/buttons/Button";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import './shared-components/subscriptions.css';
import SubscriptionRenewalDrawer from "../../../../shared-components/drawers/SubscriptionRenewalDrawer";
import ChangeSubscriptionCard from "../../../../shared-components/drawers/ChangeSubscriptionCard";
import SubscriptionPaymentReceiptModal from "../../../../shared-components/modals/SubscriptionPaymentReceiptModal";

function Subscriptions() {
    const user = useRecoilValue(userAtom);
    const { t } = useTranslation();
    const [selectedSubscriptionPayment, setSelectedSubscriptionPayment] = useRecoilState(selectedSubscriptionPaymentAtom)
    const [loading, setLoading] = useState(false);
    const [pageSize, setPageSize] = useState(15);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalSubs, setTotalSubs] = useState(0);
    const [userSubscriptions, setUserSubscriptions] = useState([]);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [reload, setReload] = useRecoilState(reloadSubscriptionsAtom);
    const [changeSubscriptionPaymentMethod, setChangeSubscriptionPaymentMethod] = useRecoilState(changeSubscriptionPaymentMethodAtom);

    const ref = useRef();
    const openDrawer = () => {
      setIsDrawerOpen(true);
    };
  
    const closeDrawer = () => {
      setIsDrawerOpen(false);
      setPlanSelected(null);
    };
      const [planSelected, setPlanSelected] = useState(null);
      const [selectedButtonIndex, setSelectedButtonIndex] = useState(null);
    // const buttonVariants = {
    //     initial: { y: 1000, opacity: 0 }, // Initial position (from the right) and invisible
    //     animate: { y: 0, opacity: 1 }, // Animate to the original position and visible
    //     exit: { y: 1000, opacity: 0 }
    //   };

    function getUserSubscriptions(page) {
      setLoading(true);
      bookingApi
        .get(`subscriptions/v2/${user?.id}?page=${page}&size=${pageSize}`)
        .then((response) => {
          setLoading(false);
          setTotalSubs(response.data.data.total);
          let tempSubs = [];
          if (page === 0) {
            tempSubs = [...response.data.data.subscriptions];
          } else {
            tempSubs = [...userSubscriptions, ...response.data.data.subscriptions];
          }
          setUserSubscriptions(tempSubs);
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
      }

    function updateSubscription(sub) {
      setLoading(true);
      bookingApi
        .put('subscriptions/user/subscription', {
          UserSubscriptionId: sub.id,
          AutoRenew: sub.autoRenew,
          CardId: sub.cardId,
          PayPalAgreementId: sub.payPalAgreementId,
          UserId: sub.userId,
        })
        .then((response) => {
          setLoading(false);
          setUserSubscriptions([]);
          setCurrentPage(0);
          getUserSubscriptions(0);
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
      }

      useEffect(() => {
        if (!changeSubscriptionPaymentMethod.drawer && changeSubscriptionPaymentMethod.update) {
          updateSubscription({
            id: changeSubscriptionPaymentMethod.selectedSubscription.id,
            autoRenew: true,
            cardId: changeSubscriptionPaymentMethod.selectedCardId,
            payPalAgreementId: changeSubscriptionPaymentMethod.selectedSubscription.payPalAgreementId,
            userId: changeSubscriptionPaymentMethod.selectedSubscription.userId,
          })
          setChangeSubscriptionPaymentMethod({
            drawer: false,
            selectedCardId: null,
            selectedSubscription: null,
            update: false
          });
        }
      }, [changeSubscriptionPaymentMethod]);

      useEffect(() => {
        if (user) {
          setUserSubscriptions([]);
          setCurrentPage(0);
          getUserSubscriptions(0);
        }
      }, [user, reload]);

      useEffect(() => {
        setChangeSubscriptionPaymentMethod({
          drawer: false,
          selectedCardId: null,
          selectedSubscription: null,
          update: false
        });
      }, []);

      // const handleRenewal = (index) => {
      //   setSelectedButtonIndex(index);
      //   setPlanSelected(selectedSubscription);
      //   openDrawer();
      // };

    return (
      <div key="main" className="overflow-auto">
        {/* <div key="sub-header" className="text-lg md:text-xl font-extrabold tracking-tight text-white mt-4 md:mt-8 ml-4">
            {t('my_subscriptions')}
          </div> */}
        <div key="subs-subheader" className="ml-4 py-2 flex flex-row z-50">
          <div key="reload" className='mr-4 mt-2 text-sm'>
            {`${userSubscriptions && userSubscriptions.length > 0 ? `You have ${userSubscriptions.length} subscription(s)` : 'You have no subscriptions'}`}
          </div>
          <Button
            key="reload-btn"
            text={t('reload')}
            textColor="text-white"
            disabled={loading}
            loading={loading}
            onClick={() => {
              setUserSubscriptions([]);
              setCurrentPage(0);
              getUserSubscriptions(0);
            }}
            loaderColor="bg-white"
            className="bg-green-600 hover:bg-green-800 w-20 mr-2"
            />
        </div>
        <div key="sub-list">
        {userSubscriptions && _.map(userSubscriptions, (sub, index) => (
            <motion.div 
              key={index}
              id={index}
              className={`${!sub?.isActive ? "border-red-700" : "border-white"} mx-4 border rounded-md border-dashed p-4 w-auto mb-4`}
              initial={{
                x: 1000,
                opacity: 0,
                transition: {
                  duration: 0.3,
                  ease: [0.43, 0.13, 0.23, 0.96],
                },
              }}
              animate={{
                x: 0,
                opacity: 1,
                transition: {
                  duration: 0.3,
                  ease: [0.43, 0.13, 0.23, 0.96],
                },
              }}
              exit={{
                x: 1000,
                opacity: 0,
                transition: {
                  duration: 0.3,
                  ease: [0.43, 0.13, 0.23, 0.96],
                },
              }}>
              <div className="flex">
                <div>
                  <div className={`${sub.isActive ? "block" :"hidden" }`}>
                  <div className="text-green-700 font-semibold">{sub.isActive ? 'Active' : 'Expired'}</div>

                  <div className="font-semibold">{sub.subscription?.nameEn}</div>
                  <div>Created: {moment(sub.createdAt).add(-4,'hour').format('MMM DD, YYYY h:mm a')}</div>
                  <div>Description: {sub.subscription?.descriptionEn}</div>
                  {sub.subscription?.location?.nameEn && (
                    <div>Location: {sub.subscription?.location?.nameEn}</div> 
                  )}
                  {sub?.isActive && <div>Expires {moment().to(sub.endDate)}</div>}
                  {sub.subscription.allowRenewal && (
                    <>
                      <div className="flex space-x-2">
                        {sub?.autoRenew && (
                          <div>Auto-Renew: </div>
                        )}
                        {!sub?.autoRenew && (
                          <button
                            type="button"
                            className="underline text-yellow-400 hover:text-yellow-500"
                            onClick={() => {
                              if (sub?.cardId) {
                                updateSubscription({
                                  ...sub,
                                  autoRenew: true
                                });
                              } else {
                                setChangeSubscriptionPaymentMethod({
                                  drawer: true,
                                  selectedCardId: null,
                                  selectedSubscription: sub,
                                  update: false
                                });
                              }
                            }}
                          >
                            Enable Auto-Renew{sub?.card ? ':' : ''}
                          </button>
                        )}
                        {sub?.card && (
                          <div className="flex space-x-2">
                            <div>{sub?.card.brand} <FontAwesomeIcon className="text-xs" icon={faTimes} />{sub?.card.last4}</div>
                            <button
                              type="button"
                              className="underline text-yellow-400 hover:text-yellow-500"
                              onClick={() => {
                                setChangeSubscriptionPaymentMethod({
                                  drawer: true,
                                  selectedCardId: sub?.cardId,
                                  selectedSubscription: sub,
                                  update: false
                                });
                              }}
                            >
                              Change
                            </button>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                  <div>Started: {moment(sub.startDate).add(-4,'hour').format('MMM DD, YYYY hh:mm a')}</div>
                  <div>Ends: {moment(sub.endDate).add(-4,'hour').format('MMM DD, YYYY hh:mm a')}</div>               
                  <div>Benefit(s)</div>
                  {sub.subscription?.benefits && _.map(sub.subscription?.benefits, (b, i) => (
                    <div key={i} className="mx-4">
                      {b.limit ? <div>{`${b?.nameEn} ( ${b.limit} ${getRecurrence(b.recurrence)})`}</div> : <div>{`${b?.nameEn} (unlimited ${getRecurrence(b.recurrence)})`}</div>}
                    </div>         
                  ))}
                   {(!sub.allowRenewal && !sub.isActive && sub.lastPayment?.status !== 2) && 
                    <div className="flex flex-col">
                        <p className="renew-text">This subscription is not renewable.</p>
                        <p className="renew-text">
                        You may choose from other available subscriptions <a className="text-blue-600" href="https://www.thecubepr.com/subscriptions">here</a>.
                        </p>
                    </div>
                    }
                  </div>
                  <div className={`${!sub.isActive ? "block" :"hidden" }`}>
                    {!sub.isActive && (sub.lastPayment?.status === 1 || !sub.lastPayment) && (
                      <div className="text-red-600 font-semibold">Expired</div>    
                    )}
                    {!sub.isActive && sub.lastPayment?.status === 0 && (
                      <div className="text-red-600 font-semibold">Failed</div>    
                    )}
                    {!sub.isActive && sub.lastPayment?.status === 2 && (
                      <div className="text-yellow-400 font-semibold">Pending</div>    
                    )}
                  <div className="font-semibold">{sub.subscription?.nameEn}</div>
                  <div>Created: {moment(sub.createdAt).add(-4,'hour').format('MMM DD, YYYY h:mm a')}</div>
                  <div>Description: {sub.subscription?.descriptionEn}</div>
                  <div>Location: {sub.subscription?.location?.nameEn}</div> 
                  <div>Started: {moment(sub.startDate).add(-4,'hour').format('MMM DD, YYYY hh:mm a')}</div>
                  <div>Ended: {moment(sub.endDate).add(-4,'hour').format('MMM DD, YYYY hh:mm a')}</div>               
                  <div>Benefit(s)</div>
                  {sub.subscription?.benefits && _.map(sub.subscription?.benefits, (b, i) => (
                    <div key={i} className="mx-4">
                      <div>{`${b?.nameEn} ( ${b.limit} ${getRecurrence(b.recurrence)})`}</div>
                    </div>         
                  ))}
                  {!sub.isActive && sub.allowRenewal && sub.subscription.isActive && sub.lastPayment?.status === 1 && 
                  <Button
                    key="renew-btn"
                    text={t('renew')}
                    textColor="text-white"
                    disabled={loading}
                    loading={loading}
                    onClick={() => {
                      setSelectedButtonIndex(index);
                      setPlanSelected(sub);
                      openDrawer();
                    }}
                    loaderColor="bg-white"
                    className="renew-btn bg-orange-600 hover:bg-orange-800 w-20 mx-2 p-2 mt-2"
                    />}
                    {!sub.isActive &&!sub.allowRenewal && sub.lastPayment?.status !== 2 &&
                     <div className="flex flex-col">
                     <p className="renew-text">This subscription is not renewable.</p>
                     <p className="renew-text">You may choose from other available subscriptions.</p>
                   </div>
                    }   
                    {!sub.isActive && sub.allowRenewal && !sub.subscription.isActive && sub.lastPayment?.status !== 2 && 
                    <p className="renew-text">This subscription is no longer available for renewal.</p>
                    }             
                  </div>
                </div>
                {sub.lastPayment && (
                  <button
                    aria-label="Receipt"
                    className="ml-auto"
                    type="button"
                    onClick={() => setSelectedSubscriptionPayment(sub.lastPayment)}
                  >
                    <FontAwesomeIcon className="text-3xl" icon={faReceipt} />
                  </button>
                )}
              </div>
            </motion.div>
          ))}
        <div className="flex">
            {userSubscriptions?.length < totalSubs && (
              <Button
                text={t('load_more')}
                textColor="text-white"
                disabled={loading}
                loading={loading}
                onClick={() => {
                  const newPage = currentPage + 1;
                  setCurrentPage(newPage)
                  getUserSubscriptions(newPage);
                }}
                loaderColor="bg-white"
                className="mx-auto mt-4 bg-customgreen hover:ring-2 ring-offset-2 ring-green-500 text-xl"
              />
            )}
          </div>
          {planSelected && (
            <SubscriptionRenewalDrawer isOpen={isDrawerOpen} selectedSubscription={planSelected} closeDrawer={closeDrawer} />
          )}
          <ChangeSubscriptionCard />
        </div>
        <SubscriptionPaymentReceiptModal reloadOnClose={selectedSubscriptionPayment?.isNew} />
      </div>
    )
}
export default Subscriptions
import { React, useState } from 'react'
import _ from 'lodash'
import { Disclosure } from '@headlessui/react'
import { motion, AnimatePresence } from 'framer-motion'; // Import AnimatePresence and motion
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'
import SearchBox from '../../shared-components/search/Search'
import { useTranslation } from 'react-i18next'

const faqs = [
  {
    question: "¿How do our packages work?",
    answer:
      "Each package has a specific amount of clases included for the specific duration of the package you choose. When choosing a package make sure the amount of classes corespond to your interest. Any additional clases will be charged individually once your limit has been reached.",
    terms:
      "packages, memberships, clases",
  },
  {
    question: "Can I take more than one class per day?",
    answer:
      "Yes, if your package is based on the number of classes, you can use it for individual sessions. If you have the unlimited package, all classes are included.",
    terms:
      "packages, memberships, clases",
  },
  {
    question: "How far in advance should I book and cancel a class?",
    answer:
      "You can book up to a week in advance. To cancel without penalty, do so more than 2 hours before the class.",
    terms:
      "cancellations, memberships, clases",
  },
  {
    question: "What time should I arrive for a class?",
    answer:
      "We recommend arriving 10 to 15 minutes before the class starts.",
    terms:
      "cancellations, memberships, clases",
  },
  {
    question: "¿How does the the membership autorenew option work?",
    answer:
      "The autorenewal is an option on some packages. If chosen an automatic charge will be attempted on the end date of the subcription against the chosen payment method on file.",
      terms: "memberships, packages, autorrenewal",
  },
  {
    question: "¿Can I turn off the Autorenewal option in my subscription?",
    answer:
      "Yes at any time you can go to the My Subscriptions section and turn off the Auto-Renewal option. If any discounts are granted for auto renewal these will not apply if renewed manually.",
      terms: "memberships, packages, autorrenewal",
  },
  {
    question: "¿How can I check my bookings for classes?",
    answer:
      "You can navigate to the My Reservations section in the profile and see the details there. You will be able to see past and future reservations to classes.",
      terms: "reservations, bookings, classes",
  },
  {
    question: "¿I forgot my password, how can I recover it?",
    answer:
      "If you forgot your password you can request a password reset via email. The link is located in the login page below the password box. The text reads Recover Password. An email will be sent to the email you provide if an account already exists with that email.",
      terms: "password, email, recover",
  },
  {
    question: "Do you have restrooms and showers?",
    answer:
      "Yes, we have two restrooms with three showers in the main area and three additional restrooms with showers on the second floor.",
      terms: "restrooms, showers, facilities",
  },
  {
    question: "Do I need experience to take the classes?",
    answer:
      "No, our classes are designed for all levels, and we offer modifications based on your needs.",
      terms: "experience, classes, levels",
  },
  {
    question: "What should I do if I get sick or have an emergency and need to pause my training?",
    answer:
      "We do not pause packages, but if you email us with an explanation and medical evidence, we will evaluate your case.",
      terms: "experience, classes, levels",
  },
  {
    question: "How can I contact you?",
    answer:
      "By email: fitboxingpr@gmail.com or sammybauzo@gmail.com or you can call By Phone/WhatsApp: 787-602-8974. Instagram: @fit_boxing ",
      terms: "contact, email, phone",
  },
  {
    question: "What is Placetopay?",
    answer: "Placetopay is the electronic payment platform used by Fit Boxing LLC to process online transactions generated in the virtual store with the payment methods enabled for this purpose."
  },
  {
    question: "How can I pay?",
    answer: "In the virtual store of Fit Boxing LLC, you can make your payment using the methods enabled for this purpose. Depending on the payment options chosen by the merchant, you can pay through Visa, MasterCard and Discover."
  },
  {
    question: "Is it safe to enter my card details on this website?",
    answer: "Fit Boxing LLC delegates the handling of sensitive information to Placetopay to protect your data. Our payment platform complies with the highest standards of the international PCI DSS regulation for secure transactions with credit, debit, and prepaid cards. Additionally, it has an SSL security certificate issued by GeoTrust, a Verisign company, which ensures secure communications by encrypting all data to and from the site. This way, you can feel secure when entering your card information. During the payment process, the browser displays the name of the authenticated organization, the certifying authority, and the address bar turns green. These features are immediately visible and provide trust to complete the transaction with Placetopay."
  },
  {
    question: "Can I make a payment any day and at any time?",
    answer: "Yes, with Fit Boxing LLC, you can shop online 24/7 with just a click."
  },
  {
    question: "Can I change the payment method?",
    answer: "If you have not yet completed your payment, you can return to the initial step and choose your preferred payment method. Once the purchase is finalized, it is no longer possible to change the payment method."
  },
  {
    question: "Does paying electronically have any cost for me as a buyer?",
    answer: "No, electronic payments made through Placetopay do not incur additional costs for the buyer."
  },
  {
    question: "What should I do if my transaction did not go through?",
    answer: "First, check if you received a payment confirmation email in the email account you provided at the time of payment. If you did not receive it, contact Fit Boxing LLC to confirm the status of the transaction. If your transaction was declined, verify that the account information is valid, enabled for online purchases, and that you have sufficient funds or credit available. If the issue persists, contact Fit Boxing LLC."
  },
  {
    question: "What should I do if I did not receive the payment receipt?",
    answer: "For every approved transaction through Placetopay, you will receive a payment receipt with the purchase reference at the email address you provided during payment. If you do not receive it, contact 787.602.8974 or fitboxingpr@gmail.com to request a resend to the same email address. Alternatively, you can send your request to sammybauzo@gmail.com."
  },
  {
    question: "I didn’t receive the product I purchased. What should I do?",
    answer: "Verify whether the transaction was successful with your card issuer. Check Fit Boxing PR to identify the delivery times."
  },
  // {
  //   question: "Is it safe to enter my identity document to proceed with the payment process?",
  //   answer: "Yes. It is important for the document to be validated during the payment process to correctly identify the cardholder’s information. Placetopay uses this information so the financial institution can recognize its correct origin and ensure security. This information will not be disclosed at any time and is used solely for transaction security and fraud prevention."
  // }
  // More questions...
]

export default function Example() {
  const { t } = useTranslation();
  const [filteredFaqs, setFilteredFaqs] = useState(faqs);
  const [searchResult, setSearchResult] = useState('');

  const CustomMinusIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20 12H4"/>
    </svg>
  );
  
  const CustomPlusIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"/>
    </svg>
  );
  

  const handleSearch = (searchText) => {
    setSearchResult(searchText);
    if(searchText === '') 
    { 
      setFilteredFaqs(null);
      return 
    }
    setFilteredFaqs(_.filter(faqs, (item) => {
      // Check if the search text is found in either property1 or property2
      return _.includes((item.question).toUpperCase(), (searchText).toUpperCase()) 
            || _.includes((item.answer).toUpperCase(), (searchText).toUpperCase())
            || _.includes((item.terms).toUpperCase(), (searchText).toUpperCase());
    }));
    // Handle the search content here, e.g., perform a search
    // and set the search result in state   
  };
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-40">       
        <div className="mx-auto max-w-4xl">
          <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">{t('faq_header')}</h2>
          <div className="m-4">
            <SearchBox onSearch={handleSearch} />
            {filteredFaqs && filteredFaqs.length > 0 && <p className={searchResult === '' ? 'hidden' : 'hidden'}>Results for: {searchResult}</p>}
          </div>
          <div className="hidden">{filteredFaqs && filteredFaqs.length}</div>
          <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
            {filteredFaqs && filteredFaqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                        <span className="text-base font-semibold leading-7">{faq.question}</span>
                        <span className="ml-6 flex h-7 items-center">
                          <AnimatePresence key="faqquestions" wait>
                            {open ? (
                              <motion.div
                                key="minus"
                                initial={{ opacity: 0, rotate: -180 }}
                                animate={{ opacity: 1, rotate: 0 }}
                                exit={{ opacity: 0, rotate: -180 }}
                              >
                                <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                              </motion.div>
                            ) : (
                              <motion.div
                                key="plus"
                                initial={{ opacity: 0, rotate: 0 }}
                                animate={{ opacity: 1, rotate: -180 }}
                                exit={{ opacity: 0, rotate: 0 }}
                              >
                                <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                              </motion.div>
                            )}
                          </AnimatePresence>
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      {/* Apply animation to the content */}
                      <AnimatePresence key="faqcontent" wait>
                        {open && (
                          <motion.div
                            key="content"                            
                            initial={{ opacity: 0, x: 50 }} // Initial position and opacity
                            animate={{ opacity: 1, x: 0 }} // Animation to visible position
                            exit={{ opacity: 0, x: 50 }} // Exit animation
                            transition={{ duration: 0.5 }}
                          >
                            <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </Disclosure.Panel>
                  </>
                )}         
              </Disclosure>
            ))}           
          </dl>
          {filteredFaqs && filteredFaqs.length === 0 && <div>No results found. Try searching for key words instead.</div>}
        </div>
      </div>
    </div>
  )
}

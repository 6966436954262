import React from 'react';

export function H1({ text, className }) {
  return (
    <pre className={`${className} text-lg md:text-xl font-extrabold tracking-tight`}>
      {text}
    </pre>
  );
}

export function H2({ text, className }) {
  return (
    <pre className={`${className} text-black md:text-lg font-semibold tracking-tight`}>
      {text}
    </pre>
  );
}

export function H3({ text, className }) {
  return (
    <pre className={`${className} text-sm md:text-base font-semibold tracking-tight`}>
      {text}
    </pre>
  );
}

export function H4({ text, className }) {
  return (
    <pre className={`${className} text-xs md:text-sm font-semibold tracking-tight`}>
      {text}
    </pre>
  );
}

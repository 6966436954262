import React from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  useSetRecoilState,
  useResetRecoilState,
  useRecoilValue
} from 'recoil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faCog, faKey, faList } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';
import {
  orderDetailsModalAtom,
  tokenAtom,
  userAtom,
} from '../../../atoms/Atoms';
import Settings from './shared-components/settings/Settings';
import Password from './shared-components/password/Password';
// import HomeLink from '../../shared-components/homelink/HomeLink';
import { H2 } from '../../shared-components/typography/Title';
import User from '../../shared-components/user/User';
import { businessesSelector } from '../../../atoms/Selectors';
import envConfig from '../../../envConfig';
import styles from './shared-components/css/profile.module.css';
import Reservations from './shared-components/reservations/Reservations';
import Attendance from '../admin/Attendance';
import PaymentMethods from './shared-components/payments/PaymentMethods';
import Subscription from '../subscriptions/Subscriptions';
import Subscriptions from './shared-components/subscriptions/Subscriptions';

function Profile() {
  const { i18n, t } = useTranslation();
  const refreshStore = useResetRecoilState(businessesSelector);
  const { section } = useParams();
  const setUser = useSetRecoilState(userAtom);
  const setToken = useSetRecoilState(tokenAtom);
  const profile = JSON.parse(localStorage.getItem(`${envConfig.StorageKey}-user`));
  const isAdmin = profile && (profile.roles.includes('Admin') || profile.roles.includes('SuperAdmin'));

  const getTitle = () => {
    switch (section) {
      case 'settings':
        return {
          title: `${t('profile')}`,
        };
      case 'payments':
        return {
          title: `${t('payment_methods')}`,
        };
      case 'password':
        return {
          title: `${t('password')}`,
        };
      case 'reservations':
        return {
          title: `${t('reservations')}`,
        };
      case 'subscriptions':
        return {
          title: `${t('my_subscriptions')}`,
        };
      default:
        return `${t('profile')}`;
    }
  };

  return (
    <div className="flex flex-1 overflow-y-auto w-screen h-screen">
      <div className="hidden mt-4 md:flex flex-col flex-shrink-0 w-64 divide-y bg-black shadow-md">
        {/* <div className="flex items-center h-24 text-sm tracking-tight p-4 space-x-2 bg-secondary">
          <User className="flex-row-reverse bg-blue-500" />
        </div> */}
        <nav className="flex flex-col flex-1 overflow-auto p-4 space-y-4">
          <NavLink
            to="/profile/reservations"
            activeClassName="bg-black"
            className="flex items-center justify-start space-x-2 text-white bg-red-600 hover:bg-red-600 h-14 px-4 ring-0 transition duration-500 ease-linear rounded-lg"
          >
             <FontAwesomeIcon icon={faList} className="text-accent" />
             <span>{t('Reservations')}</span>
          </NavLink>
          <NavLink
            to="/profile/subscriptions"
            activeClassName="bg-gray-100"
            className="flex items-center justify-start space-x-2 text-white bg-red-600 hover:bg-red-600 h-14 px-4 ring-0 transition duration-500 ease-linear rounded-lg"
          >
             <FontAwesomeIcon icon={faClock} className="text-accent" />
             <span>{t('my_subscriptions')}</span>
          </NavLink>
          <NavLink
            to="/profile/settings"
            activeClassName="bg-gray-100"
            className="flex items-center justify-start space-x-2 text-white bg-red-600 hover:bg-red-600 h-14 px-4 ring-0 transition duration-500 ease-linear rounded-lg"
          >
            <FontAwesomeIcon icon={faCog} className="text-accent" />
            <span>{t('profile')}</span>
          </NavLink>
          <NavLink
            to="/profile/payments"
            activeClassName="bg-black"
            className="flex items-center justify-start space-x-2 text-white bg-red-600 hover:bg-red-600 h-14 px-4 ring-0 transition duration-500 ease-linear rounded-lg"
          >
            <FontAwesomeIcon icon={faCog} className="text-accent" />
            <span>{t('payment_methods')}</span>
          </NavLink>
          <NavLink
            to="/profile/password"
            activeClassName="bg-black"
            className="flex items-center justify-start space-x-2 text-white bg-red-600 hover:bg-red-600 h-14 px-4 ring-0 transition duration-500 ease-linear rounded-lg"
          >
            <FontAwesomeIcon icon={faKey} className="text-accent" />
            <span>{t('password')}</span>
          </NavLink>
          <NavLink
            to="/admin/attendance"
            activeClassName="bg-black"
            className={`${profile && !isAdmin ? 'flex items-center justify-start space-x-2 text-white bg-red-600 hover:bg-red-600 h-14 px-4 ring-0 transition duration-500 ease-linear rounded-lg' : 'hidden'}`}  
          >
            <FontAwesomeIcon icon={faKey} className="text-accent" />
            <span>{t('attendance')}</span>
          </NavLink>       
        </nav>
        {/* <button
          type="button"
          className="flex items-center justify-center space-x-2 text-accent font-medium bg-yellow-500 hover:bg-gray-100 h-14 px-4 text-xs ring-0 focus:ring-0 outline-none focus:outline-none transition duration-500 ease-linear"
          onClick={() => {
            setUser(null);
            setToken(null);
            refreshStore();
            window.localStorage.removeItem(`${envConfig.StorageKey}-user`);
            window.localStorage.removeItem(`${envConfig.StorageKey}-state`);
            window.localStorage.removeItem(`${envConfig.StorageKey}-card`);
            window.localStorage.removeItem(`${envConfig.StorageKey}-token`);
          }}
        >
          <div>{t('sign_out')}</div>
        </button> */}
      </div>
      <div className="flex flex-col flex-1 overflow-y-auto">
        <motion.div
          // initial={{
          //   x: 10,
          //   opacity: 0,
          //   transition: {
          //     duration: 0.5,
          //     ease: [0.43, 0.13, 0.23, 0.96],
          //   },
          // }}
          // animate={{
          //   x: 0,
          //   opacity: 1,
          //   transition: {
          //     duration: 0.5,
          //     ease: [0.43, 0.13, 0.23, 0.96],
          //   },
          // }}
          // exit={{
          //   y: 10,
          //   opacity: 0,
          //   transition: {
          //     duration: 0.5,
          //     ease: [0.43, 0.13, 0.23, 0.96],
          //   },
          // }}
          className="flex flex-col flex-shrink-0 justify-center h-auto md:h-24 px-4 py-4 md:py-0"
        >
          {/* <HomeLink /> */}
          <div className="text-lg md:text-xl font-extrabold tracking-tight text-white">
            {getTitle().title}
          </div>
        </motion.div>
        <motion.div
          initial={{
            y: 100,
            opacity: 0,
            transition: {
              duration: 0.5,
              ease: [0.43, 0.13, 0.23, 0.96],
            },
          }}
          animate={{
            y: 0,
            opacity: 1,
            transition: {
              duration: 0.5,
              ease: [0.43, 0.13, 0.23, 0.96],
            },
          }}
          exit={{
            y: 100,
            opacity: 0,
            transition: {
              duration: 0.5,
              ease: [0.43, 0.13, 0.23, 0.96],
            },
          }}
        >
           {/* {section === 'subscriptions' && <Subscriptions /> } */}
          {section === 'settings' && <Settings />}
          {section === 'subscriptions' && <Subscriptions />}
          {section === 'payments' && <PaymentMethods />}
          {section === 'attendance' && <Attendance />}
          {section === 'password' && <Password />}
          {section === 'reservations' && <Reservations /> }         
        </motion.div>
      </div>
    </div>
  );
}

export default Profile;

import React, { useEffect, useState } from 'react';
import './shared-components/css/home.css';
import './shared-components/css/floatingbutton.css';
import moment from 'moment'; // Import moment library for date manipulation
import { useTranslation } from 'react-i18next';
import OverlayMessage from './shared-components/OverlayMessage';
import { useRecoilValue } from 'recoil';
import { userAtom } from '../../../atoms/Atoms';
import bookingApi from '../../../api/bookingApi';
import { Link, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import {
  fadeOutMotion,
  fadeInMotion
} from '../../../functions';
import HomeSections from '../../shared-components/home-sections/HomeSections';


function Home() {
  const { t} = useTranslation();
  const navigate = useNavigate();
  const user = useRecoilValue(userAtom);
  const [showRedBanner, setShowRedBanner] = useState(false);
   const [isVisible, setIsVisible] = useState(true);

  function getUserSubscriptions() {
    bookingApi
      .get(`subscriptions/${user?.id}`)
      .then((response) => {
        checkSubscriptions(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const checkSubscriptions = (sub) => {
    const today = moment();
    const oneWeekAgo = moment().subtract(7, 'days');

    const hasExpiredSubscription = sub.some((subscription) => {
      const endDate = moment(subscription.endDate).add(-4, 'hour');
      return endDate.isBetween(oneWeekAgo, today, null, '[]');
    });

    setShowRedBanner(hasExpiredSubscription);
  };


  useEffect(() => {
    if (user) {
      getUserSubscriptions();
    }
  }, [user]);

      const images = [
      ];


    const [currentIndex, setCurrentIndex] = useState(0);
    const [cycles, setCycles] = useState(-1);
    const maxCycles = 1; // Set the maximum number of cycles
    

    // Limited Cycles
    let interval; // Declare the interval variable here

    useEffect(() => {
      interval = setInterval(() => {
        if (currentIndex === images.length-2) {
            setCycles((prevCycles) => prevCycles + 1);
          }
          setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 1500); // Change image every 2 seconds
      return () => {
        clearInterval(interval); // Clear the interval in the cleanup function
      };
    }, [currentIndex]);
  
    useEffect(() => {
      if (cycles >= maxCycles) {
        clearInterval(interval); // Clear the interval when the desired number of cycles is reached
      }
    }, [cycles]);

    useEffect(() => {
      const timer = setTimeout(() => {
        setIsVisible(false);
      }, 20000);
  
      return () => clearTimeout(timer);
    }, []);
     

    return (
      <div>
        {showRedBanner && (
          <Link to="/profile/section/subscriptions" className="red-banner-link">
            <div className="red-banner">
              Your subscription is expired. Click here to renew.
            </div>
          </Link>
        )}
         {isVisible && (
        <motion.div
          initial={fadeOutMotion}
          animate={fadeInMotion}
          exit={fadeOutMotion}
        >
          <div className="main-banner hidden">
            We will be open for registration on December 30th, 2024.
          <Link to="/subscriptions" className="banner-link">
          <div>
          <p className="text-white underline">Check out available memberships here</p>
        </div>
          {/* <div className="orange-banner">
            <p className="font-semibold tracking-tighter text-gray-700 px-2">We will be opening the doors to our new facility located at Meca Complex this week for interested clients to learn about our methodology.</p>
          <Link to="/events/Kb" className="banner-link">
          <div>
          <p className="text-white underline">Book a space for free here</p>
        </div> */}
      </Link>
      </div>
        </motion.div>
      )}
       
        <div className="main-body">
          <div className="mb-4" />
          <OverlayMessage className="main-content" />
          <HomeSections />
        </div>
        <button 
          className="floating-button hidden" 
          onClick={() => {
            navigate('/timeslots');
          }}
         >
            {t('book_now')}
        </button>
      </div>
    );
  }

export default Home;

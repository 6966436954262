import React from "react";
import { motion } from "framer-motion";
import Img1 from '../../../assets/images/gallery/alex.jpg';
import Img2 from '../../../assets/images/gallery/angie.jpg';
import Img3 from '../../../assets/images/gallery/fernando.jpg';
import Img4 from '../../../assets/images/gallery/sammy.jpg';
import Img5 from '../../../assets/images/gallery/michelle.jpg';
import Img6 from '../../../assets/images/gallery/gabriel.jpg';
import Img7 from '../../../assets/images/gallery/jomarie.jpg';
import Img8 from '../../../assets/images/photos/trainerright.jpg';
import Img9 from '../../../assets/images/photos/trainersmall.jpg';
import Img10 from '../../../assets/images/photos/rightkick.jpg';

import "./shared-components/ImageGallery.css";
import Logo from "../../shared-components/logo/Logo";

const images = [
  { src: Img1, name: "Alex" },
  { src: Img2, name: "Angie" },
  { src: Img6, name: "Gabriel" },
  { src: Img7, name: "Jomarie" },
  { src: Img5, name: "Michelle" },
  { src: Img4, name: "Sammy" },
  { src: Img3, name: "Fernando" },
].sort((a, b) => a.name.localeCompare(b.name));


const transition = { duration: 3 };

const Gallery = () => {
  return (
    <div>
      <Logo />
      <div className="image-gallery-container">
        <h1 className="gallery-title my-4">Our Team</h1>
        <div className="image-gallery">
          {images.map((image, index) => (
            <motion.div
              key={index}
              className="image-container"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={transition}
            >
              <div className="">
                <img src={images[index].src} alt={`${images[index].name}`} />
                <p className="image-caption bg-red-600 text-white">{`${images[index].name}`}</p>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Gallery;

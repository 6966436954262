import React from "react";
import "../shared-components/EventCard.css";
import { motion } from "framer-motion";
import { useTranslation } from 'react-i18next';

function EventCard({ imageSrc, eventName, eventDate, eventId }) {
  const { t } = useTranslation();
  const handleButtonClick = () => {
    window.location.href = `/event/${eventId}`;
  };

    // Animation variants for the parent and child
  const containerVariants = {
      hidden: { opacity: 1 },
      visible: {
          transition: {
              delayChildren: 1.0, // Delay before the first child starts animating
              staggerChildren: 0.7, // Delay between child animations
          },
      },
  };

  const itemVariants = {
      hidden: { opacity: 0, y: 100 },
      visible: { opacity: 1, y: 0 },
  };


  return (  
    <motion.div
            className="main-section"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
        >
    <motion.div
      className="service-card" 
      variants={itemVariants}
      key={eventId}>
      <div className="card-image-container">
        <img src={imageSrc} alt="EventImage" />
        <div className="tag hidden ">Coming Soon</div>
      </div>
      <div className="card-content">
        <h1>{eventName}</h1>
        <p className="date">{eventDate}</p>
        <button 
          className="services-register-btn"
          onClick={handleButtonClick}>       
          <a href="#" className="">
            {t('learn_more')}
          </a>
        </button>
      </div>
    </motion.div>
    </motion.div>
  );
}

export default EventCard;

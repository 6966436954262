// Navbar.js
import React, { useState } from 'react';
import { useMount } from 'react-use';
import './shared-components/navbar.css';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import WarningBanner from '../warning-banner/WarningBanner';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import bookingApi from '../../../api/bookingApi';

const navbarOptionsStyles = {
  color: 'white',
  margin: 10,
  textDecoration: 'none',
  fontSize: '1.4rem',
  transition: 'color 0.7s, background-color 0.7s', // Add transition for smooth effect
};

const activeLinkStyles = {
  color: 'white',
  fontSize: '1.4rem',
  fontWeight: 'medium', // Set the font weight to bold for active links
  backgroundColor: 'transparent', // Set a transparent background for active links
  borderBottom: '2px solid rgb(250, 237, 0)', // Add an underline for active links
  transition: 'color 0.7s, background-color 0.7s, border-bottom 0.7s', // Add transitions for a smoother effect
};


const Navbar = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const { t, i18n } = useTranslation();
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
  const utc = require('dayjs/plugin/utc')
  dayjs.extend(utc)

  return (
    <div>
      <div className="navbar-full">
        <div className="navbar-container">
          {/* <a className="navbar-option" style={{
              ...(window.location.pathname === '/' ? activeLinkStyles : {}),
            }} href="/">
            About
          </a> */}
          {/* <a style={{
              ...navbarOptionsStyles,
              ...(window.location.pathname === '/' ? activeLinkStyles : {}),
            }} href="/">
            About
          </a> */}
          <a style={{
              ...navbarOptionsStyles,
              ...(window.location.pathname === '/subscriptions' ? activeLinkStyles : {}),
            }} href="/subscriptions">
            Packages
          </a>
           {/* <a style={{
              ...navbarOptionsStyles,
              ...(window.location.pathname === '/event/kE' ? activeLinkStyles : {}),
            }} href="/event/kE">
            Train
          </a> */}
          <a style={{
              ...navbarOptionsStyles,
              ...(window.location.pathname === '/services' ? activeLinkStyles : {}),
            }} href="/services">
            Classes
          </a>
          {/* <a style={{
              ...navbarOptionsStyles,
              ...(window.location.pathname === '/events' ? activeLinkStyles : {}),
            }} href="/events">
            Services
          </a> */}
          <a style={{
              ...navbarOptionsStyles,
              ...(window.location.pathname === '/faq' ? activeLinkStyles : {}),
            }} href="/faq">
           FAQ
          </a>
          <a style={{
              ...navbarOptionsStyles,
              ...(window.location.pathname === '/gallery' ? activeLinkStyles : {}),
            }} href="/gallery">
           Instructors
          </a>
          <a style={{
              ...navbarOptionsStyles,
              ...(window.location.pathname === '/timeslots' ? activeLinkStyles : {}),
            }} href="/timeslots">
          {t('book_now')}
          </a>
        </div>       
      </div>
      <div className="navbar-dd">
        <div onClick={toggleDropdown}>
          <div className="navbar-title flex flex-row">
            <span>Menu </span>
            <ChevronDownIcon className="h-6 w-6 ml-2" aria-hidden="true" />
          </div>
        </div>
        {showDropdown && (
          <div>
            <div className="nav-dropdown">
              {/* <a className="navbar-option" href="/">About</a> */}
              <a className="navbar-option" href="/subscriptions">Packages</a>
              <a className="navbar-option" href="/services">Classes</a>
              <a className="navbar-option" href="/faq">FAQ</a>
              <a className="navbar-option" href="/gallery">Instructors</a>
              <a className="navbar-option" href="/timeslots">{t('book_now')}</a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
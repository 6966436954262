// Modal.js
import React, { useRef } from 'react';
import { useClickAway } from 'react-use';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { videoModalAtom } from '../../../atoms/Atoms';
import { backdropVariants } from '../../../utils';
import ReactPlayer from 'react-player';

function VideoPlayerModal() {
  const { i18n, t } = useTranslation();
  const ref = useRef();
  const [videoModal, setVideoModal] = useRecoilState(videoModalAtom);

  useClickAway(ref, () => {
    setVideoModal(null);
  });

  return (
    <AnimatePresence>
      {videoModal && (
        <motion.div
          variants={backdropVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
          style={{ zIndex: 1001 }}
          className="fixed bg-gray-800 inset-0 bg-opacity-75 z-10 flex items-end md:items-center justify-center px-0 md:px-4"
        >
          <div className="text-gray-800 antialiased font-sans justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative my-6 mx-auto md:mx-32 px-4">
              <div
                ref={ref}
                className="border-0 rounded-xl shadow-lg relative flex flex-col w-full outline-none focus:outline-none p-6"
              >
                <div className="flex items-center justify-end text-white">
                  <button
                    type="button"
                    onClick={() => {
                      setVideoModal(null);
                    }}
                    className="hover:opacity-75 outline-none focus:outline-none text-white mb-4"
                  >
                    <FontAwesomeIcon icon={faTimesCircle} className="text-xl text-white" />
                  </button>
                </div>
                <ReactPlayer controls={true} volume={0.25} url={videoModal.url} />
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default VideoPlayerModal;

import React from "react";
import EventGrid from "./shared-components/EventGrid";
import "./services.css"; // External CSS for grid layout    


function Services() {
  return (
    <div className="services-page-container">
        <div className="services-gradient-overlay"></div> {/* Add the gradient overlay */}
        <h1 className="services-header">Our Classes</h1>
        <EventGrid />
 
    </div>
  );
}

export default Services;

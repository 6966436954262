// Modal.js
import React, { useRef } from 'react';
import { useClickAway } from 'react-use';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { AnimatePresence, motion } from 'framer-motion';
import moment from 'moment';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { selectedBookingPaymentAtom } from '../../../atoms/Atoms';
import { backdropVariants } from '../../../utils';
import { H1, H2, H3 } from '../../shared-components/typography/Title';
import { thousand } from '../../../functions';

function BookingPaymentReceiptModal() {
  const { i18n, t } = useTranslation();
  const ref = useRef();
  const [selectedBookingPayment, setSelectedBookingPayment] = useRecoilState(selectedBookingPaymentAtom);

  function mapStatus() {
    if (selectedBookingPayment?.failed || selectedBookingPayment?.isFailed) {
      return t('failed');
    }
    if (selectedBookingPayment?.isPending) {
      return t('pending');
    }
    return t('success');
  }
  console.log(selectedBookingPayment);

  useClickAway(ref, () => {
    setSelectedBookingPayment(null);
  });

  return (
    <AnimatePresence>
      {selectedBookingPayment && (
        <motion.div
          variants={backdropVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
          style={{ zIndex: 1001 }}
          className="fixed bg-gray-800 inset-0 bg-opacity-75 z-10 flex items-end md:items-center justify-center px-0 md:px-4"
        >
          <div className="text-gray-800 antialiased font-sans justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative my-6 mx-auto md:mx-32 px-4">
              <div
                ref={ref}
                className="border-0 rounded-xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-6"
              >
                <div className="flex items-center justify-end">
                  <button
                    type="button"
                    onClick={() => {
                      setSelectedBookingPayment(null);
                    }}
                    className="hover:opacity-75 outline-none focus:outline-none"
                  >
                    <FontAwesomeIcon icon={faTimesCircle} className="text-xl" />
                  </button>
                </div>
                {!selectedBookingPayment.isFailed && (
                  <>
                    <H1 text={selectedBookingPayment?.isNew ? (selectedBookingPayment?.isFailed ? 'Payment Failed' : 'Thank you for your purchase!') : 'Receipt'} />
                    <H3 text={selectedBookingPayment?.eventNameEn} />
                    {selectedBookingPayment?.isNew ? dayjs(selectedBookingPayment.createdAt).format('dddd, MMMM D h:mm A') : dayjs(selectedBookingPayment.createdAt).add(-4, 'hour').format('dddd, MMMM D h:mm A')}
                    <div>Status: {mapStatus()}</div>
                    {selectedBookingPayment?.internalReference && (
                      <div>Reference: {selectedBookingPayment?.internalReference}</div>
                    )}
                    {selectedBookingPayment?.card && (
                      <div>Payment: {selectedBookingPayment?.card.brand} <FontAwesomeIcon className="text-xs" icon={faTimes} />{selectedBookingPayment?.card.last4}</div>
                    )}
                    {(selectedBookingPayment?.paypalInvoiceNumber && selectedBookingPayment?.paypalReferenceNumber)&& (
                      <div>Payment: PayPal</div>
                    )}
                    <div>Amount (USD): ${thousand(selectedBookingPayment?.amount)} {selectedBookingPayment?.refunded && '(Refunded)'}</div>
                  </>
                )}
                {selectedBookingPayment.isFailed && (
                  <>
                    <H1 className="text-center" text="Payment Failed"/>
                    <H2 className="text-center" text={selectedBookingPayment.message}/>
                    {selectedBookingPayment.referenceCode && (
                      <div>Reference: {selectedBookingPayment.referenceCode}</div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default BookingPaymentReceiptModal;

import React from 'react';
import { useLocalStorage } from 'react-use';
import envConfig from '../../../../envConfig';

function Avatar() {
  const [user] = useLocalStorage(`${envConfig.StorageKey}-user`);

  if (user) {
    return (
      <div className="relative h-8 w-8 md:h-10 md:w-10">
      <div className="absolute inset-0 rounded-full ring-2 ring-rose-400 ring-offset-1 animate-ping"></div>
      <img
        alt={user.completeName}
        src={user.avatar}
        className="relative rounded-full h-full w-full bg-blue-200"
      />
      </div>
    );
  }

  return null;
}

export default Avatar;

import React from 'react';
import { useRecoilValue } from 'recoil';
import { userAtom } from '../../../atoms/Atoms';
import Avatar from './shared-components/Avatar';
import envConfig from '../../../envConfig';
import './shared-components/user.css';
import '../../../assets/css/custom.css';
import { formatPhoneNumber } from '../../../functions';

function User() {
  const user = useRecoilValue(userAtom);
  if (user) {
    const truncateName = (name) => {
      return name.length > 10 ? name.substring(0, 10) + '...' : name;
    };

    return <div className="fontDefaults userStyle flex flex-row-reverse md:flex-row text-xs md:text-sm justify-end">
      <div className="mx-2">
        <div className="font-medium tracking-tight truncate hidden md:block">
        {user?.completeName}
        </div>
        <div className="font-medium text-md tracking-tight truncate block md:hidden">
        {truncateName(user?.firstName)}
        </div>
        <div className="font-medium text-md tracking-tight truncate block md:hidden">
        {truncateName(user?.lastName)}
        </div>
        <div className="font-light tracking-tight truncate hidden md:block">{user.email}</div>
        <div className="font-light tracking-tight truncate hidden hidden">{formatPhoneNumber(user.phoneNumber)}</div>
        <div className="font-light tracking-tight truncate text-yellow-300 text-xs hidden">v 2025.2</div>
      </div>
      <Avatar />
      </div>
  }
  return null;
}

export default User;

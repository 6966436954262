import React, { useEffect, useState, useRef } from 'react';
import { useClickAway } from 'react-use';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { AnimatePresence, motion } from 'framer-motion';
import { Menu, Transition } from '@headlessui/react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faTimesCircle,
  faCreditCard,
  faMapPin,
} from '@fortawesome/free-solid-svg-icons';  
import bookingApi from '../../../api/bookingApi';
import api from '../../../api/api';
import {
  selectedRentalTimeSlot,
  userPaymentsAtom,
  notificationsAtom,
  userAtom,
  selectedBookingPaymentAtom
} from '../../../atoms/Atoms';
import { Spinner } from '../../shared-components/Spinner';
import Button from '../../shared-components/buttons/Button';
import {
  backdropVariants,
  sidebarVariants,
  modalVariants
} from '../../../utils';
import envConfig from '../../../envConfig';
import './shared-components/events.css';
import { PlaceToPayLogo } from '../../../utils'

function Events() {
  const navigate = useNavigate();
  const showPaymentButton = envConfig.ShowPaymentButtonOnBookingReservation;
  const { t, i18n } = useTranslation();
  const ref = useRef();
  const paymentsRef = useRef();
  const dayjs = require('dayjs')
  const utc = require('dayjs/plugin/utc')
  dayjs.extend(utc)
  const endDateOptions = [
    { title: `4 ${t('days')}`, date: dayjs().utc().add(4, 'day').format('YYYY-MM-DDTHH:00:00.00') },
    { title: t('today'), date: dayjs().utc().add(1, 'day').format('YYYY-MM-DDTHH:00:00.00') },
    { title: `3 ${t('days')}`, date: dayjs().utc().add(3, 'day').format('YYYY-MM-DDTHH:00:00.00') },
    { title: `5 ${t('days')}`, date: dayjs().utc().add(5, 'day').format('YYYY-MM-DDTHH:00:00.00') },
    { title: `14 ${t('days')}`, date: dayjs().utc().add(14, 'day').format('YYYY-MM-DDTHH:00:00.00') },
  ]
  // const startDate = dayjs().utc().format('YYYY-MM-DDTHH:00:00.00');
  const [currentIp, setCurrentIp] = useState('');
  const user = useRecoilValue(userAtom);
  const setSelectedBookingPayment = useSetRecoilState(selectedBookingPaymentAtom);
  const [loading, setLoading] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [events, setEvents] = useState([]);
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const [loadingCards, setLoadingCards] = useState(false);
  const [cards, setCards] = useState([]);
  const [selectedTimeSlot, setSelectedTimeSlot] = useRecoilState(selectedRentalTimeSlot);
  const [endDate, setEndDate] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [timeSlotDays, setTimeSlotDays] = useState([]);
  const [showPaymentsModal, setShowPaymentsModal] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
//   const [activeItemIndex, setActiveItemIndex] = useState(0);
//   const breakpoint = useBreakpoint();
  const closePaymentsModal = () => {
    setShowPaymentsModal(false);
  };

  function handleCheckboxChange() {
    setIsChecked(!isChecked);
  }

  function handleToggle() {
    setIsExpanded(!isExpanded);
  }

  function getCards() {
    setLoadingCards(true);
    api
      .get('users/me/cards')
      .then((response) => {
        setLoadingCards(false);
        if (response.data.error) {
          setCards([]);
        } else {
          setCards(response.data.data.results);
        }
      })
      .catch((error) => {
        setLoadingCards(false);
        setCards([]);
        console.log(error.message);
      });
  }

  const agreementsList = [
    { text: t('event_agreement_option_1'), index: '1' },
    // { text: t('event_agreement_option_2'), index: '2' },
    { text: t('event_agreement_option_3'), index: '3' }
  ];

//   function fetchPayments() {
//     setLoading(true);
//     api
//       .get('users/me/cards')
//       .then((response) => {
//         setLoading(false);
//         if (response.data.error) {
//           setNotifications([
//             ...notifications,
//             {
//               title: `${t('load_error')}`,
//               description: response.data.error,
//               error: true,
//             },
//           ]);
//         } else {
//           setPayment({ ...payment, cards: response.data.data.results });
//         }
//       })
//       .catch((err) => {
//         setLoading(false);
//         setNotifications([
//           ...notifications,
//           {
//             title: `${t('load_error')}`,
//             description: err.message,
//             error: true,
//           },
//         ]);
//       });
//   }

   function searchTimeSlots() {
    if (!endDate) {
      return;
    }
    setTimeSlotDays([]);
    setLoading(true);
    bookingApi
      .post('events/search/days/grouped', {
        searchTerm: null,
        type: 2,
        hostId: null,
        locationId: null,
        // endDate: endDate?.date
      })
        .then((response) => {
          setLoading(false);
          setEvents(_.sortBy(response.data.data, 'name'));
        //   if (response.data.data.length > 0) {
        //     setSelectedEvent(_.sortBy(response.data.data, 'name')[0]);
        //   }
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
  }

  function createReservation(card, payPalInvoice, payPalReference, athMovilReference) {
    setLoading(true);
    bookingApi.post('events/booking', {
      EventTimeSlotId: selectedTimeSlot?.id,
      UserCardId: card?.id,
      PaypalInvoiceNumber: payPalInvoice,
      PaypalReferenceNumber: payPalReference,
      AthMovilReferenceNumber: athMovilReference,
      Amount: selectedTimeSlot?.event?.price,
      BypassPayment: false,
      UserAgent: navigator.userAgent,
      UserIp: currentIp
    })
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          if (response.data.message && !response.data.data.isFailed && !response.data.data.failed) {
            // setNotifications([
            //   ...notifications,
            //   {
            //     title: '',
            //     description: response.data.message,
            //     error: false,
            //   },
            // ]);
            setSelectedBookingPayment({
              ...response.data.data,
              message: response.data.message,
              reference: response.data.data.referenceCode,
              isFailed: true
            });
          } else if (response.data.message && response.data.data.invalidatedCard) {
            let tempMessage = response.data.message;
            if (!tempMessage.endsWith('.')) {
              tempMessage += '.';
            }
            tempMessage += ` ${t('card_invalidated')}`;
            // setNotifications([
            //   ...notifications,
            //   {
            //     title: `${t('oops')}`,
            //     description: tempMessage,
            //     error: true
            //   },
            // ]);
            setSelectedBookingPayment({
              ...response.data.data,
              message: tempMessage,
              reference: response.data.data.referenceCode,
              isFailed: true
            });
            getCards();
          }
          setSelectedBookingPayment({
            ...response.data.data,
            isNew: true
          });
          closePaymentsModal();
          searchTimeSlots();
        } else {
          setNotifications([
            ...notifications,
            {
              title: `${t('load_error')}`,
              description: response.data.error,
              error: true,
            },
          ]);
        }
      })
      .catch((err) => {
        setLoading(false);
        setNotifications([
          ...notifications,
          {
            title: `${t('load_error')}`,
            description: err.message,
            error: true,
          },
        ]);
      });
  }

  const close = () => {
    if (showPaymentsModal) {
      closePaymentsModal();
    } else {
      setSelectedTimeSlot(null);
    }
  };

  useClickAway(ref, () => {
    close()
  });

  useClickAway(paymentsRef, () => {
    closePaymentsModal()
  });

  useEffect(() => {
    if (endDate) {
      searchTimeSlots();
    }
  }, [endDate]);

  useEffect(() => {
    setEndDate(endDateOptions[4]);
  }, []);

  useEffect(() => {
    if (showPaymentsModal) {
      getCards();
    }
  }, [showPaymentsModal]);

  useEffect(() => {
    if (selectedEvent) {
      const tempDays = _.sortBy(selectedEvent?.timeslotDays, 'startDate');
      setTimeSlotDays(tempDays);
      if (selectedDate === null) {
        setSelectedDate(0); 
      }
      if (selectedTimeSlot !== null) {
        const filtered = _.filter(tempDays[selectedDate].timeSlots,
          (slot) => slot.id === selectedTimeSlot.id);
        setSelectedTimeSlot(filtered[0]);
      }
    }
  }, [selectedEvent]);

  
  return (
    <AnimatePresence key="events">
      <div
        className="my-2 mx-4 mb-2"
      >
        <div className="flex">
          <div>
            <div className="font-semibold mt-1 ml-2 md:text-lg text-md">{t('events')}</div>
            <div className="font-light mt-1 ml-2 md:text-md text-xs">{t('events_subheader')}</div>
          </div>
          <div className="ml-auto mt-1">  
          <button
            key="reload"
            type="button"
            hidden={loading}
            onClick={() => {
            searchTimeSlots();
            }}
            className="md:text-md text-xs bg-green-500 hover:bg-green-600 text-white font-semibold py-1 px-4 border border-green-400 rounded shadow md:mx-2"
          >
            <span>{t('refresh')}{loading ? <Spinner className="ml-2 inline" /> : <></>}</span>
          </button>
          </div>
        </div>
        <div
          className="grid overflow-y-auto grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mt-2"
          style={{ height: '70vh' }}
        >
          {events && _.map(events, (e, i) => (
            <button
              type="button"
              key={`${e.id} ${i}`}
              onClick={() => {
                navigate(`${e.id}`)
              }}
              className="events-style bg-yellow-600 rounded-lg text-center shadow"
            >
              <div className="p-4">
                <img className="mx-auto object-cover h-auto max-h-72 rounded-lg" hidden={!e.imageUrl} src={e.imageUrl} alt={e.name} />
                <h3 className="mt-2 text-xl font-bold text-white">{e.name}</h3>
                <dl className="mt-1 flex flex-grow flex-col justify-between">
                <dd className="text-sm text-white">{e.title}</dd>
                <dd className="mt-1">
                    <span className="px-2 py-0 text-md font-medium text-white tracking-tight ellipsis line-clamp-2">
                    {e.description}
                    </span>
                </dd>
                <dd className="mt-3">  
                    <div className="rounded-full bg-gray-700 px-2 py-2 text-sm font-medium text-white h-10">  
                      <FontAwesomeIcon icon={faMapPin} className="mr-2" />
                      {e.location.name}
                    </div>
                </dd>
                </dl>
              </div>
            </button>
          ))}
        </div>
      </div>
      <div className="my-2 mx-4 h-full" key="menu">
        <div className="flex space-x-2 mt-2">
          <div className="hidden">
            <div className="relative z-20 mt-2 md:block hidden">
              <Menu as="div" className="relative flex justify-end items-center">
                {({ open }) => (
                  <>
                    <Menu.Button className="w-full flex items-center justify-between rounded-md shadow-sm px-4 py-3 space-x-4 bg-white text-sm font-medium hover:bg-gray-50 outline-none focus:outline-none">
                      <span>{endDate?.title}</span>
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        className={open ? 'transform rotate-180 transition duration-500 easy-in-out' : 'transition duration-500 easy-in-out'}
                      />
                    </Menu.Button>
                    <Transition
                      show={open}
                      as={React.Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items
                        static
                        className="absolute right-0 top-10 w-full mt-2 origin-bottom bg-white divide-y divide-gray-100 rounded-xl shadow outline-none max-h-60 no-scrollbar"
                      >
                        {_.map(endDateOptions, (e, i) => (
                          <Menu.Item
                            key={`${e.title} ${i}`}
                            as="button"
                            type="button"
                            className={`${e.title === endDate?.title && 'text-accent'} disabled:cursor-not-allowed text-sm font-light tracking-tight text-left flex items-center space-x-2 w-full p-4 leading-none hover:text-accent transition duration-500 easy-in-out truncate line-clamp-1`}
                            onClick={() => setEndDate(e)}
                          >
                            <span>{e.title}</span>
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </>
                )}
              </Menu>
            </div>
          </div>
        </div>
      </div>
      {selectedTimeSlot && !showPaymentsModal && (
        <motion.div
          variants={backdropVariants}
          initial="hidden"
          animate="visible"
          key="sidebar"
          exit="hidden"
          className="fixed bg-gray-800 inset-0 bg-opacity-75 z-20"
        >
          <motion.div
            ref={ref}
            variants={sidebarVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            className="absolute right-0 h-full flex flex-col w-full md:w-1/3 bg-white"
          >
            <div className="px-4 py-6 flex items-center justify-between bg-gray-100">
              {t('details')}
              <button
                type="button"
                onClick={() => {
                  setSelectedTimeSlot(null);
                  setIsChecked(false);
                }}
                className="hover:opacity-75 outline-none focus:outline-none"
              >
                <FontAwesomeIcon icon={faTimesCircle} className="text-xl" />
              </button>
            </div>
            <div className="flex flex-col flex-1 overflow-auto p-4">
              {selectedTimeSlot?.event?.imageUrl && (
                // Shows image if one is set to the event or location
                <img alt={selectedTimeSlot?.event?.name} src={selectedTimeSlot?.event?.imageUrl} className="h-52 w-auto object-cover rounded-lg" />
              )}
              <div className="flex">
                  <div className="my-2 text-md font-bold">{selectedTimeSlot?.event?.name}</div>
                  <div className="my-2 ml-auto text-md font-bold">${selectedTimeSlot?.event?.price.toFixed(2)}</div>
              </div>
              <div className="my-2 text-xs md:text-sm tracking-tight text-justify">{selectedTimeSlot?.event?.description}</div>   
              <div className="text-sm mt-1">{t('date')}: {`${dayjs(selectedTimeSlot?.startTime).add(-4, 'hour').format('dddd MMMM D, YYYY')}`}</div>
              <div className="text-sm mt-1">{t('slot')}: {`${dayjs(selectedTimeSlot?.startTime).add(-4, 'hour').format('h:mm a')}-${dayjs(selectedTimeSlot?.endTime).add(-4, 'hour').format('h:mm a')}`}</div>        
              <div className="text-sm mt-1">{t('location')}: {`${selectedEvent?.location.name}`}</div>
              <div className={selectedTimeSlot?.comment ? '' : 'hidden'}>{t('comments')}: {selectedTimeSlot?.comment}</div>
              {!showPaymentButton && (
                <>
                  <div className="my-2 text-md font-medium">{`${!selectedTimeSlot?.isFull ? t('contact_us_for_booking') : ''}`}</div>
                  <a href={`mailto:${envConfig.ContactEmail}`} className={`${selectedTimeSlot?.isFull ? 'hidden' : 'font-medium text-blue-600 dark:text-blue-500 hover:underline'}`}>bookings@mecacomplex.com</a>
                </>
              )}
              {!user && (
                <Button
                  text={t('login_to_dropin')}
                  textColor="text-white"
                  onClick={() => {
                  navigate('/login', { from: 'events' });
                }}
                  loaderColor="bg-white"
                  className="bg-accent text-lg hover:bg-accent mt-4"
                />
              )}
              {!selectedTimeSlot?.isFull && !selectedTimeSlot?.reservedByUser && user && (
              <div>
                <div className="flex flex-col items-start mt-8 bg-red-100 rounded-xl p-4 border-t border-gray-100">
                  <label className="text-sm font-semibold">{t('event_terms')}</label>
                  <button 
                    hidden
                    type="button"
                    onClick={handleToggle}
                  >
                    {isExpanded ? 'show less' : 'show less'} List
                  </button>
                {isExpanded && (
                  <ul className="text-sm">
                    {_.map(agreementsList, (item, i) => (
                      <li key={`${item.index} ${i}`}>-{item.text}</li>
                    ))}
                  </ul>
                )}
                <div className="text-sm mt-4">
                <span>
                  {t('if_cancel_booking_contact')} 
                  <a href="/profile/reservations" className="text-yellow-500 underline ml-1">here</a>.
                </span>
                </div>
                </div>          
              <div className="flex items-center mx-1 mt-8 mb-4">
                <input
                  type="checkbox"
                  className="h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
                <label htmlFor="agreement" className="ml-2 text-sm">
                  {t('booking_i_agree_erms')}
                </label>
              </div>
              </div>)}
              {selectedTimeSlot?.reservedByUser && user && (
                <div className="my-4 text-center text-white bg-purple-600 rounded-xl p-2 text-lg">
                {t('already_reserved')}
                </div>
              )}
              {showPaymentButton && !selectedTimeSlot?.isFull 
              && !selectedTimeSlot?.reservedByUser && user && (
                <Button
                  text={t('complete_payment')}
                  textColor="text-white"
                  disabled={loading || !isChecked}
                  loading={loading}
                  onClick={() => {
                    setShowPaymentsModal(true);
                    setIsChecked(false);
                  }}
                  loaderColor="bg-white"
                  className="bg-customgreen hover:ring-2 ring-offset-2 ring-green-500 text-lg"
                />
              )}
              {showPaymentButton && (selectedTimeSlot?.isFull ||
               (selectedTimeSlot.maxCapacity === selectedTimeSlot.currentCapacity)) &&
                !selectedTimeSlot?.reservedByUser && user && (
                <Button
                  text={t('class_is_full')}
                  textColor="text-white"
                  disabled
                  loaderColor="bg-white"
                  className="bg-red-400 hover:ring-2 ring-offset-2 ring-red-500 text-lg mt-4"
                />
              )}  
              {selectedTimeSlot?.reservedByUser && user && (
                <div className="my-4 text-center text-purple-600 p-2 text-lg">
                {t('already_have_reservation')}
                </div>
              )}            
            </div>
          </motion.div>
        </motion.div>
      )}
      {selectedTimeSlot && showPaymentsModal && (
        <motion.div
          variants={backdropVariants}
          initial="hidden"
          animate="visible"
          key="payments"
          exit="hidden"
          className="fixed bg-gray-800 inset-0 bg-opacity-75 z-10 flex items-end md:items-center justify-center px-0 md:px-4"
        >
          <motion.div
            variants={modalVariants}
            className="text-gray-800 antialiased justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div
              ref={paymentsRef}
              className="flex flex-col bg-white rounded-xl mx-auto"
            >
              <header className="flex items-center justify-between bg-gray-800 text-white rounded-t-xl px-4 py-6">
                <div className="flex items-center space-x-1 font-bold tracking-tight text-xl leading-tight">
                  {t('amount_to_pay')}: ${(selectedTimeSlot?.event?.price).toFixed(2)}
                </div>
                <button
                  type="button"
                  onClick={() => closePaymentsModal()}
                  className="text-xl leading-none ring-0 outline-none focus:outline-none"
                >
                  <FontAwesomeIcon icon={faTimesCircle} />
                </button>
              </header>
              {loading && <Spinner className="mx-auto mt-2" />}
              <main className="group relative flex flex-1 bg-gray-50 rounded-b-xl p-4">
                <div className="flow">
                  <div>{t('payment_method_description')}</div>
                  <Spinner
                    spinning={loading}
                    className="mx-auto my-4"
                  />
                  {envConfig.PayPalForBookings === 'true' && (
                    <div className="mt-6">
                      <PayPalScriptProvider options={{ 'client-id': envConfig.PaypalClientId }}>
                        <PayPalButtons
                          createOrder={(data, actions) => actions.order.create({
                            purchase_units: [
                                {
                                    description: `${selectedTimeSlot?.event?.name} ${dayjs(selectedTimeSlot?.startTime).add(-4, 'hour').format('MMM D YYYY')} ${dayjs(selectedTimeSlot?.startTime).add(-4, 'hour').format('h:mm a')}-${dayjs(selectedTimeSlot?.endTime).add(-4, 'hour').format('h:mm a')}`,
                                    amount: {
                                        value: `${(selectedTimeSlot?.event?.price).toFixed(2)}`,
                                    },
                                    custom_id: `${user?.id} ${selectedTimeSlot?.id}`
                                },
                            ],
                            application_context: { brand_name: 'MECA Complex', locale: `${i18n.language}-US`, shipping_preference: 'NO_SHIPPING' }
                          })}
                          onApprove={(data, actions) => actions.order.capture().then((details) => {
                            const refNumber = details.id;
                            const invoiceNumber =
                              details.purchase_units[0]?.payments?.captures[0]?.id;
                            createReservation(null, invoiceNumber, refNumber, null);
                          })}
                        />
                      </PayPalScriptProvider>
                    </div>
                  )}
                  {envConfig.AthMovilForBookings === 'true' && !loading && (
                    <></>
                  )}
                  {envConfig.AllowCreditCardsInBooking === 'true' && (
                    <div className="space-y-4 mb-4">
                      {cards.length === 0 && (
                        <div style={{ fontStyle: 'italic' }} className="text-xs text-center  text-gray-300">{t('no_registered_cards')}</div>
                      )}
                      <Link to="/profile/payments">
                        <div className="text-sm text-center underline text-yellow-400">{t('click_register_new_card')}</div>
                      </Link>
                      {_.map(cards, (card) => (
                        <button
                          disabled={!isChecked || loading}
                          key={card.id}
                          type="button"
                          onClick={() => {
                            createReservation(card, null, null, null);
                          }}
                          className={`w-full px-4 ${!isChecked || loading ? 'opacity-75 cursor-not-allowed' : ''}`}
                        >
                          <div className="flex shadow rounded-xl flex h-20 transition duration-500 ease-linear ring-2 ring-offset-2 ring-purple-400 border border-purple-400 outline-none focus:outline-none">
                            <div className="w-16 flex flex-col items-center justify-center text-white text-3xl bg-purple-600 rounded-l-xl">
                              <FontAwesomeIcon icon={faCreditCard} />
                            </div>
                            <div className="flex flex-col flex-1 justify-center text-xs p-2">
                              <div className="font-semibold w-full">{card.brand}</div>
                              <div>x{card.last4}</div>
                              <div>{card.expirationDate}</div>
                            </div>
                            <div className="flex">
                              {card.paymentType === 26 && (
                                <img
                                  className="w-24 mr-2"
                                  alt="Evertec Place To Pay logo"
                                  src={PlaceToPayLogo}
                                />
                              )}
                            </div>
                          </div>
                        </button>
                      ))}
                    </div>
                  )}
                </div>
              </main>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default Events;

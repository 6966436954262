import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { notificationsAtom } from '../../../atoms/Atoms';
import bookingApi from '../../../api/bookingApi';
import Form from '../../shared-components/form/Form';
import Input from '../../shared-components/form/Input';
import Button from '../../shared-components/buttons/Button';
import { H1 } from '../../shared-components/typography/Title';
import Subtitle from '../../shared-components/typography/Subtitle';
import '../../../assets/css/custom.css';


function RecoverPassword() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email(`${t('valid_email_validation')}`)
        .required(`${t('required_field')}`),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      bookingApi
        .post(
          'auth/request/reset',
          {
            Email: values.email,
          },
          null
        )
        .then((response) => {
          if (response.data.error) {
            setNotifications([
              ...notifications,
              {
                title: `${t('oops')}`,
                description:
                `${t('password_email_error')}`,
                error: true,
              },
            ]);
            setSubmitting(false);
          } else {
            setSubmitting(false);
            setNotifications([
              ...notifications,
              {
                title: `${t('alert')}`,
                description: response.data.message,
                error: false,
              },
            ]);
            navigate('/');
          }
        })
        .catch((error) => {
          setSubmitting(false);
          setNotifications([
            ...notifications,
            {
              title: `${t('oops')}`,
              description: `${t('password_email_error')} - ${error}`,
              error: true,
            },
          ]);
        });
    },
  });

  return (
    <div className="fontDefaults flex-1 overflow-y-auto">
      <div className="flex flex-col justify-center py-6 px-4 md:px-0">
        <div className="sm:mx-auto sm:w-full sm:max-w-lg">
         <div className="loginHeader">{t('recover_password')}</div>
         <div className="loginSubheader">{t('recover_password_prompt')}</div>
          <div className="w-full mb-2">
            <Form>
              <Input
                label={t('email')}
                type="email"
                name="email"
                id="email"
                placeholder={t('sample_email')}
                value={formik.values.email}
                onChange={formik.handleChange}
                error={
                  formik.errors.email &&
                  formik.touched.email &&
                  formik.errors.email
                }
                className="fontDefaults col-span-2"
              />
            </Form>
            <div className="flex flex-row">
            <Button
              text={t('cancel')}
              textColor="text-gray-200"
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }}
              className="bg-red-500 hover:bg-red-600 w-20 mr-2 "
            />
            <Button
              text={t('recover')}
              textColor="text-gray-200"
              disabled={formik.isSubmitting}
              loading={formik.isSubmitting}
              onClick={formik.submitForm}
              loaderColor="bg-white"
              className="bg-yellow-400 hover:bg-yellow-600 w-20 "
            />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RecoverPassword;

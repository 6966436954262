import React, { useEffect, useState, useRef } from 'react';
import { useClickAway } from 'react-use';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import {
  faCreditCard,
  faCreditCardAlt,
  faEye,
  faTimesCircle,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import {
  faCcAmex,
  faCcDinersClub,
  faCcDiscover,
  faCcMastercard,
  faCcVisa,
} from '@fortawesome/free-brands-svg-icons';
import { AnimatePresence, motion } from 'framer-motion';
import { useRecoilState, useRecoilValue } from 'recoil';
import axios from 'axios';
import {
  notificationsAtom,
  tokenAtom,
  userPaymentsAtom,
} from '../../../../../../atoms/Atoms';
import api from '../../../../../../api/api';
import placeToPayApi from '../../../../../../api/placeToPayApi';
import { H1, H2, H3 } from '../../../../../shared-components/typography/Title';
import Subtitle from '../../../../../shared-components/typography/Subtitle';
import Panel from '../../../../../shared-components/panel/Panel';
import Loader from '../../../../../shared-components/loader/Loader';
import envConfig from '../../../../../../envConfig';
import { getPlaceToPayCredentials } from '../../../../../../functions';
import { useMount } from 'react-use';
import { backdropVariants, PlaceToPayLogo } from '../../../../../../utils'

function Options() {
  const { i18n, t } = useTranslation();
  const ref = useRef();
  const token = useRecoilValue(tokenAtom);
  const [payment, setPayment] = useRecoilState(userPaymentsAtom);
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const [loadingSession, setLoadingSession] = useState(false);
  const [loadingCards, setLoadingCards] = useState(false);
  const [addingCard, setAddingCard] = useState(false);
  const [currentIp, setCurrentIp] = useState('');
  const [selectedCard, setSelectedCard] = useState(null);
  const [errorMessage, setErorrMessage] = useState(null);

  const fetchPayment = () => {
    setLoadingCards(true);
    api
      .get('users/me/cards')
      .then((response) => {
        setLoadingCards(false);
        if (response.data.error) {
          console.log(response.data.error);
        } else {
          setPayment({ ...payment, cards: _.map(response.data.data.results, (c) => ({
            ...c,
            loading: false
          })) });
        }
      })
      .catch((error) => {
        setLoadingCards(false);
        console.log(error.message);
      });
  };

  const deletePayment = (id) => {
    const index = _.findIndex(payment.cards, (c) => c.id === id);
    const tempCards = JSON.parse(JSON.stringify(payment.cards));
    tempCards[index].loading = true;
    setPayment({ ...payment, cards: tempCards });
    api
      .post(`users/cards/remove/${id}`, null)
      .then((response) => {
        if (response.data.error) {
          setNotifications([
            ...notifications,
            {
              title: `${t('oops')}`,
              description:
              `${t('card_eliminated_error')}`,
              error: true,
            },
          ]);
        } else {
          setNotifications([
            ...notifications,
            {
              title: `${t('yay')}`,
              description:
              `${t('card_eliminated_success')}`,
              error: false,
            },
          ]);
          const array = _.filter(payment.cards, (card) => card.id !== id);
          setPayment({ ...payment, cards: array });
        }
      })
      .catch((error) => {
        setNotifications([
          ...notifications,
          {
            title: `${t('oops')}`,
            description: `${t('card_eliminated_error')} - ${error.message}`,
            error: true,
          },
        ]);
      });
  };

  function handlePlaceToPayResponse(response) {
    if ((window.processing === false || window.processing === undefined) && !addingCard && response.status.status === 'APPROVED') {
      setLoadingSession(true);
      window.processing = true;
      const reference = response.reference;
      const requestId = response.requestId;
      const token = response.signature;
      placeToPayApi.post(`api/session/${requestId}`, {
        auth: getPlaceToPayCredentials()
      })
      .then((detailsResponse) => {
        // setLoadingSession(false);
        if (detailsResponse.data?.subscription?.status.status) {
          const tokenizedCardFields = detailsResponse.data.subscription.instrument;
          const card = { type: 26, referenceCode: detailsResponse?.data?.request?.subscription?.reference };
          for (let i = 0; i < tokenizedCardFields.length; i++) {
            const field = tokenizedCardFields[i];
            if (field.keyword === 'token') {
              card.token = field.value;
            }
            if (field.keyword === 'franchise') {
              card.brand = field.value;
            }
            if (field.keyword === 'issuerName') {
              card.organization = field.value;
            }
            if (field.keyword === 'lastDigits') {
              card.number = field.value;
            }
            if (field.keyword === 'validUntil') {
              const dateFields = field.value.split('-');
              card.expMonth = dateFields[1];
              card.expYear = dateFields[0];
            }
          }
          setAddingCard(true);
          api.post('users/me/cards', card)
          .then((cardResponse) => {
            setAddingCard(false);
            window.processing = false;
            setLoadingSession(false);
            window.processing = false;
            if (cardResponse.data.error) {
                setNotifications([
                  ...notifications,
                  {
                    title: `${t('card_not_added')}`,
                    description:
                    `${t('card_error')}`,
                    error: true,
                  },
                ]);
              } else if (cardResponse.data.data) {
                setSelectedCard({
                  ...cardResponse.data.data,
                  isNew: true
                });
                setPayment({
                  ...payment,
                  modal: false,
                  reload: true,
                  cards: [...payment.cards, cardResponse.data.data],
                });
                setNotifications([
                  ...notifications,
                  {
                    title: t('card_added'),
                    description: t('card_success'),
                    error: false,
                  },
                ]);
                fetchPayment();
              }
          })
          .catch((error) => {
            setAddingCard(false);
            setLoadingSession(false);
            window.processing = false;
            setNotifications([
              ...notifications,
              {
                title: t('card_not_added'),
                description: error.message,
                error: true,
              },
            ]);
          });
        }
        else {
          setLoadingSession(false);
          window.processing = false;
        }
        // console.log(detailsResponse.data.status.status);
      })
      .catch((error) => {
        setLoadingSession(false);
        window.processing = false;
        setNotifications([
          ...notifications,
          {
            title: t('card_not_added'),
            description: error.message,
            error: true,
          },
        ]);
      });
    } else {
      setLoadingSession(false);
      window.processing = false;
      if (response.status.status === 'REJECTED') {
        const reference = response.reference;
        setErorrMessage({
          message: t('user_process_cancelled'),
          reference
        });
        // setNotifications([
        //   ...notifications,
        //   {
        //     title: `${t('card_not_added')}`,
        //     description:
        //     `${t('user_process_cancelled')}`,
        //     error: true,
        //   },
        // ]);
      }
    }
  }

  function getPlaceToPaySessionUrl() {
    getPlaceToPayCredentials();
    setLoadingSession(true);
    placeToPayApi.post('api/session', {
      locale: i18n.language === 'en' ? 'en_US' : 'es_PR',
      auth: getPlaceToPayCredentials(),
      subscription: {
        description: t('save_payment_option_for_later'),
        reference: uuidv4().replaceAll('-', '').toLocaleUpperCase()
      },
      returnUrl: window.location.href,
      ipAddress: currentIp,
      userAgent: navigator.userAgent,
      expiration: dayjs().add(15, 'minute').format('YYYY-MM-DDTHH:mm:ssZ')
    })
    .then((response) => {
      // setLoadingSession(false);
      if (response.data.status?.status === 'OK') {
        window.P.init(response.data.processUrl);
      } else {
        setNotifications([
          ...notifications,
          {
            title: t('oops'),
            description: t('unknown_error_occurred'),
            error: true,
          },
        ]);
      }
    });
  }

  useEffect(() => {
    if (token) {
      fetchPayment();
    }
  }, [token]);

  useEffect(() => {
    axios.get("https://api.ipify.org/?format=json")
    .then((response) => {
      setCurrentIp(response.data.ip);
    });
    getPlaceToPayCredentials();
  }, []);

  useMount(() => {
    window.P.on('response', handlePlaceToPayResponse);
  });

  useClickAway(ref, () => {
    selectedCard(null);
    setErorrMessage(null);
  });

  return (
    <Panel>
      <H3 text={t('payment_methods')} />
      <Subtitle
        text={t('payment_methods_prompt')}
      />
      <div className="flex items-center space-x-1 text-3xl py-4">
        <FontAwesomeIcon icon={faCcVisa} className="text-blue-600" />
        {/* <FontAwesomeIcon icon={faCcAmex} className="text-cyan-600" /> */}
        <FontAwesomeIcon icon={faCcMastercard} className="text-blue-800" />
        {/* <FontAwesomeIcon icon={faCcDinersClub} className="text-lightBlue-600" /> */}
        <FontAwesomeIcon icon={faCcDiscover} className="text-orange-400" />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
        <button
          type="button"
          disabled={loadingSession}
          onClick={() => getPlaceToPaySessionUrl()}
          className="text-left text-white transform hover:scale-90 shadow rounded-xl flex h-20 transition duration-500 ease-linear ring-2 ring-offset-2 ring-amber-400 border border-amber-400 outline-none focus:outline-none"
        >
          <div className="w-16 h-full flex flex-shrink-0 items-center justify-center text-white text-3xl bg-amber-400 rounded-l-xl">
            {(!loadingSession && !addingCard) && (
              <FontAwesomeIcon icon={faCreditCardAlt} />
            )}
            {(loadingSession || addingCard) && (
              <Loader color="bg-white" />
            )}
          </div>
          <div className="flex flex-col justify-center p-4 text-xs">
            <div className="font-semibold">{t('add_new_payment')}</div>
            <div>{t('new_card')}</div>
          </div>
        </button>
        {loadingCards && (
          <Loader color="bg-white mt-8 ml-4" />
        )}
        {!loadingCards && _.map(payment.cards, (card) => (
          <div
            key={card.id}
            className={`${card.paymentType === 26 ? '' : 'divide-x divide-purple-200'} flex shadow rounded-xl flex h-20 transition duration-500 ease-linear ring-2 ring-offset-2 ring-purple-400 border border-purple-400 outline-none focus:outline-none`}
          >
            <div className="w-16 flex flex-col items-center justify-center text-white text-3xl bg-purple-600 rounded-l-xl">
              <FontAwesomeIcon icon={faCreditCard} />
            </div>
            <div className="flex flex-col flex-1 justify-center text-xs p-2">
              <div className="font-semibold w-full">{card.brand}</div>
              <div>x{card.last4}</div>
              <div>{card.expirationDate}</div>
            </div>
            <div className={`${card.paymentType === 26 ? 'divide-x divide-purple-200' : ''} flex`}>
              {card.paymentType === 26 && (
                <img
                  className="w-24 mr-2"
                  alt="Evertec Place To Pay logo"
                  src={PlaceToPayLogo}
                />
              )}
              <div className="flex flex-col items-center w-10">
                <button
                  type="button"
                  onClick={() => {
                    setSelectedCard(card);
                  }}
                  className="rounded-tr-xl flex-1 text-white hover:text-gray-400 px-4 transition duration-500 ease-linear outline-none focus:outline-none ring-0 focus:ring-0"
                >
                  <FontAwesomeIcon icon={faEye} />
                </button>
                <button
                  type="button"
                  disabled={card.loading}
                  onClick={() => deletePayment(card.id)}
                  className="rounded-br-xl flex-1 text-red-600 hover:text-red-400 px-4 transition duration-500 ease-linear outline-none focus:outline-none ring-0 focus:ring-0"
                >
                  {card.loading && (
                    <Loader color="bg-white" />
                  )}
                  {!card.loading && (
                    <FontAwesomeIcon icon={faTrashAlt} />
                  )}
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
      <AnimatePresence>
        {selectedCard && (
          <motion.div
            variants={backdropVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            style={{ zIndex: 1001 }}
            className="fixed bg-gray-800 inset-0 bg-opacity-75 z-10 flex items-end md:items-center justify-center px-0 md:px-4"
          >
            <div className="text-gray-800 antialiased font-sans justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative my-6 mx-auto md:mx-32 px-4">
                <div
                  ref={ref}
                  className="border-0 rounded-xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-6"
                >
                  <div className="flex items-center justify-end">
                    <button
                      type="button"
                      onClick={() => {
                        setSelectedCard(null);
                      }}
                      className="hover:opacity-75 outline-none focus:outline-none"
                    >
                      <FontAwesomeIcon icon={faTimesCircle} className="text-xl" />
                    </button>
                  </div>
                  <H1 text={selectedCard?.isNew ? t('card_added') : t('selected_card')} />
                  <H3 text={selectedCard?.nickname}/>
                  <div>Expiration: {selectedCard?.expMonth}/{selectedCard?.expYear}</div>                  
                  <div>Reference: {selectedCard?.referenceCode}</div>
                  <div>Added: {dayjs(selectedCard.createdAt).add(-4, 'hour').format('dddd, MMMM D h:mm A')}</div>
                </div>
              </div>
            </div>
          </motion.div>
        )}
        {errorMessage && (
          <motion.div
            variants={backdropVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            style={{ zIndex: 1001 }}
            className="fixed bg-gray-800 inset-0 bg-opacity-75 z-10 flex items-end md:items-center justify-center px-0 md:px-4"
          >
            <div className="text-gray-800 antialiased font-sans justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative my-6 mx-auto md:mx-32 px-4">
                <div
                  ref={ref}
                  className="border-0 rounded-xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-6"
                >
                  <div className="flex items-center justify-end">
                    <button
                      type="button"
                      onClick={() => {
                        setErorrMessage(null);
                      }}
                      className="hover:opacity-75 outline-none focus:outline-none"
                    >
                      <FontAwesomeIcon icon={faTimesCircle} className="text-xl" />
                    </button>
                  </div>
                  <H2 className="text-center" text={errorMessage.message}/>
                  <div>Reference: {errorMessage.reference}</div>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </Panel>
  );
}

export default Options;

import React from 'react';
import { useTranslation } from 'react-i18next';
import './shared-resources/terms.css';
import envConfig from '../../../envConfig';

function Terms() {      
  const { t } = useTranslation();

return (
    <div className="flex flex-col items-center mx-auto mt-12 py-24 justify-center">
        <h3 className="font-heading font-extrabold text-3xl text-center text-white">{t('terms')}</h3>
        <br/>
        <p className="terms-text text-justify">
            {t('tos_content_1')}
        </p>
        <br/>
        <p className="terms-text text-justify">
            {t('tos_content_2')}
        </p>
        <br/>
        <p className="terms-text text-justify">
            {t('tos_content_3')}
        </p>
        <br/>
        <p className="terms-text text-justify">
            {t('tos_content_4')}
        </p>
        <p className="terms-text text-justify">
            {t('tos_content_5')}
        </p>
        <p className="terms-text text-justify">
            {t('tos_content_7')}
        </p>
    </div>
);
}

export default Terms;

import React from 'react';
import './home-sections.css';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import cross from '../../../assets/images/photos/trainerright2.jpg';
import fitness from '../../../assets/images/photos/rightkick.jpg';
import fullbody from '../../../assets/images/photos/kneepullup1.jpg';
import { motion } from 'framer-motion';


const HomeSections = () => {
    const navigate = useNavigate();

    const navigateToServices = () => 
        {
            navigate('/services');
        }

     // Animation variants for the parent and child
     const containerVariants = {
        hidden: { opacity: 1 },
        visible: {
            transition: {
                delayChildren: 0.7, // Delay before the first child starts animating
                staggerChildren: 0.7, // Delay between child animations
            },
        },
    };

    const itemVariants = {
        hidden: { opacity: 0, y: 100 },
        visible: { opacity: 1, y: 0 },
    };


return (
        <motion.div
            className="main-section"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
        >
            <motion.div
                className="image-text-section"
                variants={itemVariants}
                onClick={navigateToServices}
            >
                <div className="image-container">
                    <div className="image-overlay">
                        <img src={cross} alt="Crosstraining" className="image" />
                    </div>
                </div>
                <div className="text-container">
                    <h2>Cross Training & KickBox</h2>
                </div>
            </motion.div>

            <motion.div
                className="image-text-section"
                variants={itemVariants}
                onClick={navigateToServices}
            >
                <div className="image-container">
                    <div className="image-overlay">
                        <img src={fitness} alt="Fitness & Agility Kickboxing" className="image" />
                    </div>
                </div>
                <div className="text-container">
                    <h2>Fitness & Agility Kickboxing</h2>
                </div>
            </motion.div>

            <motion.div
                className="image-text-section"
                variants={itemVariants}
                onClick={navigateToServices}
            >
                <div className="image-container">
                    <div className="image-overlay">
                        <img src={fullbody} alt="Full Body Circuit" className="image" />
                    </div>
                </div>
                <div className="text-container">
                    <h2>Full Body Circuit</h2>
                </div>
            </motion.div>
        </motion.div>
    );
};

export default HomeSections;
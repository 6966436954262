import React, { useState, useEffect } from "react";
import EventCard from "./EventCard";
import "./EventGrid.css"; // External CSS for grid layout
import bookingApi from "../../../../api/bookingApi"
import _ from 'lodash';
import { useTranslation } from 'react-i18next';



function EventGrid() {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [locations, setLocations] = useState([]);
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);


  function searchAllEvents() {
    setLoading(true);
    bookingApi
    .get('events/active?allowNoActiveTimeSlotEvents=true')
      .then((response) => {
        setLoading(false);
        setEvents(_.sortBy(response.data.data.filter(item => item.isActive), 'name'));
        if (response.data.data.length > 0) {
          setSelectedEvent(_.sortBy(response.data.data, 'name')[0]);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  }
  
  useEffect(() => {
    searchAllEvents();
  }, []);

    // Animation variants for the parent and child
    const containerVariants = {
      hidden: { opacity: 1 },
      visible: {
          transition: {
              delayChildren: 0.7, // Delay before the first child starts animating
              staggerChildren: 0.7, // Delay between child animations
          },
      },
  };

  const itemVariants = {
      hidden: { opacity: 0, y: 100 },
      visible: { opacity: 1, y: 0 },
  };

  return (
    <div className="event-grid">     
    {_.map(events, (e, index) => (  
        <EventCard
        key={e.id}
        imageSrc={e.imageUrl}
        eventName={e.nameEn}
        eventId={e.id}
      />
    ))}
    </div>
  );
}

export default EventGrid;

// Modal.js
import React, { useRef } from 'react';
import { useClickAway } from 'react-use';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { AnimatePresence, motion } from 'framer-motion';
import moment from 'moment';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { selectedSubscriptionPaymentAtom } from '../../../atoms/Atoms';
import { backdropVariants } from '../../../utils';
import { H1, H2, H3 } from '../../shared-components/typography/Title';
import { thousand } from '../../../functions';

function SubscriptionPaymentReceiptModal({ reloadOnClose }) {
  const { i18n, t } = useTranslation();
  const ref = useRef();
  const [selectedSubscriptionPayment, setSelectedSubscriptionPayment] = useRecoilState(selectedSubscriptionPaymentAtom);

  function mapStatus(status) {
    if (status === 2) {
      return t('pending');
    }
    if (status === 1) {
      return t('success');
    }
    return t('failed');
  }

  useClickAway(ref, () => {
    setSelectedSubscriptionPayment(null);
    if (reloadOnClose) {
      window.location.reload()
    }
  });

  return (
    <AnimatePresence>
      {selectedSubscriptionPayment && (
        <motion.div
          variants={backdropVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
          style={{ zIndex: 1001 }}
          className="fixed bg-gray-800 inset-0 bg-opacity-75 z-10 flex items-end md:items-center justify-center px-0 md:px-4"
        >
          <div className="text-gray-800 antialiased font-sans justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative my-6 mx-auto md:mx-32 px-4">
              <div
                ref={ref}
                className="border-0 rounded-xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-6"
              >
                <div className="flex items-center justify-end">
                  <button
                    type="button"
                    onClick={() => {
                      setSelectedSubscriptionPayment(null);
                      if (reloadOnClose) {
                        window.location.reload()
                      }
                    }}
                    className="hover:opacity-75 outline-none focus:outline-none"
                  >
                    <FontAwesomeIcon icon={faTimesCircle} className="text-xl" />
                  </button>
                </div>
                {!selectedSubscriptionPayment.isFailed && (
                  <>
                    <H1 text={selectedSubscriptionPayment?.isNew ? (selectedSubscriptionPayment?.isRenewal ? 'Thank you for renewing!' : 'Thank you for your purchase!') : 'Receipt'} />
                    <H3 text={selectedSubscriptionPayment?.subscriptionNameEn} />
                    {selectedSubscriptionPayment?.isNew ? dayjs(selectedSubscriptionPayment.createdAt).format('dddd, MMMM D h:mm A') : dayjs(selectedSubscriptionPayment.createdAt).add(-4, 'hour').format('dddd, MMMM D h:mm A')}
                    <div>Status: {mapStatus(selectedSubscriptionPayment?.status)}</div>
                    {selectedSubscriptionPayment?.isRenewal && (
                      <div>Renewal</div>
                    )}
                    {selectedSubscriptionPayment?.internalReference && (
                      <div>Reference: {selectedSubscriptionPayment?.internalReference}</div>
                    )}
                    {selectedSubscriptionPayment?.card && (
                      <div>Payment: {selectedSubscriptionPayment?.card.brand} <FontAwesomeIcon className="text-xs" icon={faTimes} />{selectedSubscriptionPayment?.card.last4}</div>
                    )}
                    {(selectedSubscriptionPayment?.paypalInvoiceNumber && selectedSubscriptionPayment?.paypalReferenceNumber)&& (
                      <div>Payment: PayPal</div>
                    )}
                    <div>Amount (USD): ${thousand(selectedSubscriptionPayment?.amount)} {selectedSubscriptionPayment?.refunded && '(Refunded)'}</div>
                    <div>Started: {moment(selectedSubscriptionPayment?.forStartDate).add(-4,'hour').format('MMM DD, YYYY hh:mm a')}</div>
                    <div>Ends: {moment(selectedSubscriptionPayment?.forEndDate).add(-4,'hour').format('MMM DD, YYYY hh:mm a')}</div>
                  </>
                )}
                {selectedSubscriptionPayment.isFailed && (
                  <>
                    <H1 className="text-center" text="Payment Failed"/>
                    <H2 className="text-center" text={selectedSubscriptionPayment.message}/>
                    {selectedSubscriptionPayment.reference && (
                      <div>Reference: {selectedSubscriptionPayment.reference}</div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default SubscriptionPaymentReceiptModal;

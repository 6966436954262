// Drawer.js
import React, { useEffect, useState } from 'react';
import './shared-components/Drawer.css';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';
import { XMarkIcon } from '@heroicons/react/24/outline'
import { useMount } from 'react-use';
import axios from 'axios';
import {
  faCreditCard
} from '@fortawesome/free-solid-svg-icons';
import '../../../assets/css/custom.css';
import { notificationsAtom } from '../../../atoms/Atoms';
import bookingApi from '../../../api/bookingApi';
import api from '../../../api/api';
import { userAtom, reloadSubscriptionsAtom, selectedSubscriptionPaymentAtom } from "../../../atoms/Atoms";
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import TermsAcceptanceDrawer from './TermsAcceptanceDrawer';
import envConfig from '../../../envConfig';
import {
  getRecurrence,
  getDateExtensionType,
  getRecurrenceLength,
  isSameDay,
  toLocalTime
} from "../../../functions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import Loader from '../loader/Loader';
import { PlaceToPayLogo } from '../../../utils'
import { Link } from 'react-router-dom';

const SubscriptionDrawer = ({ isOpen, selectedSubscription, selectedLocation, closeDrawer }) => {
  const user = useRecoilValue(userAtom);
  const setSelectedSubscriptionPayment = useSetRecoilState(selectedSubscriptionPaymentAtom);
  const { t, i18n } = useTranslation();
  const [showPaymentsModal, setShowPaymentsModal] = useState(false);
  const [reload, setReload] = useRecoilState(reloadSubscriptionsAtom);
  const [isChecked, setIsChecked] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const [benefits, setBenefits] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const price = selectedSubscription?.price || 0; // Make sure to handle null/undefined values
  const integerPart = Math.floor(price);
  const decimalPart = (price - integerPart).toFixed(2).slice(2); // Extract two decimal places
  const [currentIp, setCurrentIp] = useState('');
  const promoPrice = selectedSubscription?.promoPrice || 0; // Make sure to handle null/undefined values
  const integerPartPromo = Math.floor(promoPrice);
  const decimalPartPromo = (promoPrice - integerPartPromo).toFixed(2).slice(2); // Extract two decimal places
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [loadingCards, setLoadingCards] = useState(false);
  const [cards, setCards] = useState([]);
  const [allowAutoRenew, setAllowAutoRenew] = useState(false);

  const handleTermsClick = () => {
    setIsDrawerOpen(true);
  };

  const handleAcceptTerms = () => {
    setTermsAccepted(true);
    setIsChecked(true)
    setIsDrawerOpen(false);
  };

  const cancelTerms = () => {
    setIsDrawerOpen(false);
    setIsChecked(false);
    setTermsAccepted(false);
   }

  function handleCheckboxChange() {
    setIsChecked(!isChecked);
  }

  function handleToggle() {
    setIsExpanded(!isExpanded);
  }

  function getCards() {
    setLoadingCards(true);
    api
      .get('users/me/cards')
      .then((response) => {
        setLoadingCards(false);
        if (response.data.error) {
          setCards([]);
        } else {
          setCards(response.data.data.results);
        }
      })
      .catch((error) => {
        setLoadingCards(false);
        setCards([]);
        console.log(error.message);
      });
  }

  function assignFreeSubscription() {
    setSubmitting(true);
    bookingApi
      .post(
        'subscriptions/subscribe',
      {
        subscriptionId: selectedSubscription.id,
        autoRenew: false,
        cardId: null ,
        payPalAgreementId: null,
        paypalReferenceNumber: '123',
        paypalInvoiceNumber: '123',
        userId: user.id,
        userAgent: navigator.userAgent,
        userIp: currentIp
      },
      null
      )
      .then((response) => {
        if (response.data.error) {
          setNotifications([
            ...notifications,
            {
              title: `${t('oops')}`,
              description: response.data.error,
              error: true,
            },
          ]);
          setSubmitting(false);
        } else {
          setSubmitting(false);
          setReload(true);
          setNotifications([
            ...notifications,
            {
              title: `${t('yay')}`,
              description: response.data.message,
              error: false,
            },
          ]);
          setSelectedSubscriptionPayment({
            ...response.data.data,
            isNew: true
          });
          closeDrawer();
        }
      })
      .catch((error) => {
        setSubmitting(false);
        console.error(error);
      });
  }

  function SubscriptionActivation(cardId,ppInvoiceNumber,ppReferenceNumber,ppAgreementId) {
    setSubmitting(true);
    bookingApi
      .post(
        'subscriptions/subscribe',
      {
        subscriptionId: selectedSubscription.id,
        autoRenew: (cardId && cardId !== null) || (ppAgreementId && ppAgreementId !== null),
        cardId: cardId,
        payPalAgreementId: ppAgreementId,
        paypalReferenceNumber: ppReferenceNumber,
        paypalInvoiceNumber: ppInvoiceNumber,
        userId: user.id,
        autoRenew: allowAutoRenew,
        userAgent: navigator.userAgent,
        userIp: currentIp
      }
      )
      .then((response) => {
        if (response.data.error && !response.data.internalMessage) {
          // setNotifications([
          //   ...notifications,
          //   {
          //     title: `${t('oops')}`,
          //     description: response.data.error,
          //     error: true,
          //   },
          // ]);
          setSelectedSubscriptionPayment({
            message: response.data.error,
            isFailed: true
          });
          setSubmitting(false);
        } else if (response.data.error && response.data.internalMessage) {
          let tempMessage = response.data.error;
          if (!tempMessage.endsWith('.')) {
            tempMessage += '.';
          }
          tempMessage += ` ${t('card_invalidated')}`;
          // setNotifications([
          //   ...notifications,
          //   {
          //     title: `${t('oops')}`,
          //     description: tempMessage,
          //     error: true
          //   },
          // ]);
          setSelectedSubscriptionPayment({
            message: tempMessage,
            isFailed: true
          });
          getCards();
          setSubmitting(false);
        } else {
          setSubmitting(false);
          if (response.data.data.isFailed) {
            if (response.data.data.invalidatedCard) {
              let tempMessage = response.data.message;
              if (!tempMessage.endsWith('.')) {
                tempMessage += '.';
              }
              tempMessage += ` ${t('card_invalidated')}`;
              setSelectedSubscriptionPayment({
                ...response.data.data,
                message: tempMessage,
                reference: response.data.data.referenceCode,
                isFailed: true
              });
            } else {
              setSelectedSubscriptionPayment({
                ...response.data.data,
                message: response.data.message,
                reference: response.data.data.referenceCode,
                isFailed: true
              });
            }
          } else {
            setReload(true);
            if (response.data.message) {
              setNotifications([
                ...notifications,
                {
                  title: t('yay'),
                  description: response.data.message,
                  error: false,
                },
              ]);
            } else {
              setNotifications([
                ...notifications,
                {
                  title: t('yay'),
                  description: t('subscribe_successfully'),
                  error: false,
                },
              ]);
            }
            setSelectedSubscriptionPayment({
              ...response.data.data,
              isNew: true
            });
            closeDrawer();
          }
        }
      })
      .catch((error) => {
        setSubmitting(false);
        console.error(error);
      });
  }

  useEffect(() => {
    if(isOpen) {
      setIsChecked(false);
      setAllowAutoRenew(false);
      getCards();
    }
  }, [isOpen]);

  useMount(() => {
    setIsChecked(false);
  });

  useEffect(() => {
    axios.get("https://api.ipify.org/?format=json")
    .then((response) => {
      setCurrentIp(response.data.ip);
    });
  }, [])

  return (
    <div className={`drawer ${isOpen ? 'open' : ''}`}>
      <button 
        className="close-button"
        onClick={ () => {
          closeDrawer();
          setIsChecked(false)
        }}>
        <div className="flex flex-row">
          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
          <div className="ml-2">Close</div>
        </div>
      </button>
      {user &&
      <div className="drawer-content">
        {/* Your drawer content goes here */}
        {!selectedSubscription?.isSubscribed && 
        <div>
        <div className="text-yellow-300">You are about to purchase</div>
        <div className="text-yellow-300 mb-2">the following membership</div>       
        </div>}
        <div className="text-xl">{`${selectedSubscription && selectedSubscription?.nameEn}`}</div>
        <div className={`${selectedSubscription?.isOnPromo ? 'block' : 'hidden'}`}>{`${selectedSubscription && selectedSubscription?.descriptionEn}`}</div>
        {selectedLocation && <div>
          <div className="accentText mt-0">@</div>
          <div>{selectedLocation.nameEn}</div>
        </div>}

        <div className={`${selectedSubscription?.isOnPromo ? 'block font-semibold mt-4' : 'hidden'}`}>{t('special_offer')}</div>
        {selectedSubscription?.isOnPromo && (
          <div>
            <FontAwesomeIcon className="mr-2" icon={faClock} />
            {isSameDay(toLocalTime(selectedSubscription?.promoEndDate), toLocalTime(dayjs()).startOf('date')) ? t('ends_today') : `${t('ends_on')} ${toLocalTime(selectedSubscription?.promoEndDate).format("MMM DD, YYYY")}`}
          </div>
        )}

        <div className={`${selectedSubscription?.isOnPromo && selectedSubscription?.promoPrice ? 'text-yellow-300 mt-4 text-2xl' : 'hidden'}`}>
          <div>
            <span className="text-white text-sm line-through ml-1"> ${integerPart}</span>
            <span className="text-white text-sm line-through">.{decimalPart}</span>
            {selectedSubscription?.recurrence > 0 && <span className="text-white text-sm line-through"> / {t(getRecurrenceLength(selectedSubscription?.recurrence))}</span>}
          </div>
          <span className="">${integerPartPromo}</span>
          <span className="text-sm">.{decimalPartPromo}</span>
          {selectedSubscription?.recurrence > 0 && <span className="text-yellow-300 text-2xl"> / {t(getRecurrenceLength(selectedSubscription?.recurrence))}</span>}
        </div>
        <div className={`${selectedSubscription?.isOnPromo && selectedSubscription?.promoPrice ? 'hidden' : 'text-yellow-300 mt-4 text-2xl'}`}>
          <span className="text-yellow-300">${integerPart}</span>
          <span className="text-sm">.{decimalPart}</span>
          {selectedSubscription?.recurrence > 0 && <span className="text-yellow-300 text-2xl"> / {t(getRecurrenceLength(selectedSubscription?.recurrence))}</span>}
        </div>

        {selectedSubscription?.isOnPromo && selectedSubscription?.recurrence > 0 && selectedSubscription?.dateExtensionType > 0 && (
          <div className="font-bold">+{selectedSubscription?.dateExtensionValue} {t(getDateExtensionType(selectedSubscription?.dateExtensionType))} {t('free')}</div>
        )}

        <ul id="benefits" className="benefitsList">
          {selectedSubscription && <div className="accentText">Included Benefit(s)</div>}
          {selectedSubscription && _.map(selectedSubscription?.benefits, (b, bIndex) => (
          <li
            key={`${bIndex}`}
        >
           <div key={`div-${bIndex}`}>{`${b?.nameEn} (${b?.limit} ${getRecurrence(b.recurrence)})`}</div>
          </li>
          ))}
        </ul>
        { selectedSubscription?.isSubscribed && 
        <div className="mt-8 text-sm mb-6 border border-dashed rounded-lg p-4">You are already enrolled in this subscription. Please go to the <a className="text-yellow-500" href="/profile/subscriptions">my subscriptions</a> section in your profile to renew.</div>}
         {!selectedSubscription?.isSubscribed && 
         <div id="agrements" className="flex flex-col content-start agreementList">
          <div className="accentText">Terms</div>
          <div key="0">All sales are final</div>
          <div key="1">No refunds</div>
          <div key="2">Start of cycle (Date of purchase)</div>
          
          {selectedSubscription?.recurrence === 0 && (
            <div key="3" className="flex">
              <div className="mr-2">{t('duration')}:</div>
              <div className="lowercase space-x-1">
                {selectedSubscription?.dateExtensionValue} {t(getDateExtensionType(selectedSubscription?.dateExtensionType))}
              </div>
            </div>
          )}
          
          {/* <div key="2">Length of cycle (one month)</div> */}
          {/* <div key="3">Renews automatically</div> */}
          {/* <div key="4">Cancel anytime</div> */}
        </div>}
        {!selectedSubscription?.isSubscribed && 
        <div className="accentText flex items-center mx-1 mt-8 mb-4">
                <input
                  type="checkbox"
                  className="agreementCheckbox"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
                <label htmlFor="agreement" className="acceptance"> 
                  I have read and accept the{" "}
                  <span className="terms" onClick={handleTermsClick}>
                    <u>terms</u>
                  </span>.
                </label>
              </div>}
      </div>}     
      {/* Terms Acceptance Drawer component */}
      <TermsAcceptanceDrawer isOpen={isDrawerOpen} onClose={handleAcceptTerms} onCancelTerms={cancelTerms}>
        <h2>Terms and Conditions</h2>
      </TermsAcceptanceDrawer>
      {user && selectedSubscription?.price > 0 && !selectedSubscription?.isSubscribed && 
      <div className="mt-10 mx-4">
        {(!selectedSubscription?.isSubscribed && selectedSubscription?.allowRenewal) && 
        <div className="accentText flex items-center mx-2 mt-8 mb-4">
                <input
                  type="checkbox"
                  className="agreementCheckbox"
                  checked={allowAutoRenew}
                  onChange={(obj => setAllowAutoRenew(!allowAutoRenew))}
                />
                <label htmlFor="autorenew" className="acceptance"> 
                  Allow Auto Renew
                </label>
              </div>
        }
        {(loadingCards || submitting) && (
          <div className="flex justify-center mb-4">
            <Loader color="bg-white" />
          </div>
        )}
        <div className="mt-8" />
        {(isOpen && envConfig.PayPalForBookings === 'true') && (
          <PayPalScriptProvider options={{ 'client-id': envConfig.PaypalClientId,'disable-funding': 'card', 'enable-funding': 'venmo'}}>
            <PayPalButtons
              disabled={!isChecked || submitting}
              createOrder={(data, actions) => actions.order.create({
                purchase_units: [
                    {
                        description: `${selectedSubscription?.isOnPromo ? `${selectedSubscription?.nameEn} ` : ''}${selectedSubscription?.descriptionEn} ${selectedLocation?.nameEn} Starts: ${moment().add(-4, 'hour').format('MMM D YYYY')}`,
                        amount: {
                            value: selectedSubscription?.isOnPromo ? `${(selectedSubscription?.promoPrice).toFixed(2)}` : `${(selectedSubscription?.price).toFixed(2)}`,
                        },
                        custom_id: `${user?.id} ${selectedSubscription?.id}`
                    },
                ],
                application_context: { brand_name: 'Fit Boxing', locale: `${i18n.language}-US`, shipping_preference: 'NO_SHIPPING' }
              })}
              onApprove={(data, actions) => actions.order.capture().then((details) => {
                // console.log('JSON Response', details);
                // console.log('JSON Response string', JSON.stringify(details));
                // console.log('selected Subscription id', selectedSubscription.id);
                // console.log('user id', user.id);
                const refNumber = details.id;
                const invoiceNumber =
                  details.purchase_units[0]?.payments?.captures[0]?.id;
                SubscriptionActivation(null, invoiceNumber, refNumber, null);
              })}
            />
          </PayPalScriptProvider>
        )}
        
        {(envConfig.AllowCreditCardsInBooking === 'true' && !loadingCards) && (
          <div className="space-y-4 mb-4">
            {cards.length === 0 && (
              <div style={{ fontStyle: 'italic' }} className="text-xs text-gray-300">{t('no_registered_cards')}</div>
            )}
            <Link to="/profile/payments">
              <div className="text-sm text-center underline text-yellow-400">{t('click_register_new_card')}</div>
            </Link>
            {_.map(cards, (card) => (
              <button
                disabled={!isChecked || submitting}
                key={card.id}
                type="button"
                onClick={() => {
                  SubscriptionActivation(card.id, null, null, null);
                }}
                className={`w-full px-4 ${!isChecked || submitting ? 'opacity-75 cursor-not-allowed' : ''}`}
              >
                <div className="flex shadow rounded-xl flex h-20 transition duration-500 ease-linear ring-2 ring-offset-2 ring-purple-400 border border-purple-400 outline-none focus:outline-none">
                  <div className="w-16 flex flex-col items-center justify-center text-white text-3xl bg-purple-600 rounded-l-xl">
                    <FontAwesomeIcon icon={faCreditCard} />
                  </div>
                  <div className="flex flex-col flex-1 text-left justify-center text-xs p-2">
                    <div className="font-semibold w-full">{card.brand}</div>
                    <div>x{card.last4}</div>
                    <div>{card.expirationDate}</div>
                  </div>
                  <div className="flex">
                    {card.paymentType === 26 && (
                      <img
                        className="w-24 mr-2"
                        alt="Evertec Place To Pay logo"
                        src={PlaceToPayLogo}
                      />
                    )}
                  </div>
                </div>
              </button>
            ))}
          </div>
        )}
      </div>
      }
      {user  && !selectedSubscription?.isSubscribed  &&  selectedSubscription?.price > 0 && user.email === 'y@gopranza.com' && 
      <div>
        <button
          disabled={!isChecked} 
          className="athmovil-button"> ATH Movil
        </button>
      </div>}
      {user && selectedSubscription?.price === 0 && user.email === 'y@gopanza.com' && !selectedSubscription?.isSubscribed && 
      <div>
        <button
          disabled={!isChecked && submitting} 
          onClick={() => assignFreeSubscription()}
          className="accentButton mt-10 px-4 py-2"> Assign Subscription

        </button>
      </div>}
      {!user && 
      <div className="mt-24 px-6">Please <a className="text-yellow-500" href="/login">login</a> to purchase a subscription</div>}
    </div>
  );
};

export default SubscriptionDrawer;
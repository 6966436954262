import React from 'react';
import { Transition } from '@headlessui/react';
import envConfig from '../../../envConfig';
import { useTranslation } from 'react-i18next';
import { formatPhoneNumber } from '../../../functions';
import '../../../components/shared-components/footer/shared-resources/footer.css';

function Footer() {
    const { t } = useTranslation();
  return (
    <Transition
      appear
      show
      key="footer"
      enter="transform transition duration-700 ease-in-out"
      enterFrom="opacity-0 translate-y-full"
      enterTo="opacity-100 translate-y-0"
      leave="transform duration-200 transition ease-in-out"
      leaveFrom="opacity-100 -translate-x-0"
      leaveTo="opacity-0 -translate-x-full"
      className="flex footerStyle z-20 justify-center items-center flex-shrink-0 font-medium text-sm px-4"
    >
      <div className="justify-center items-center text-center text-xs">
        <div>{t('copyright')} © {new Date().getFullYear()} Fit Boxing</div> 
        <div className="flex p-2 justify-center items-center text-center">
          <a href="/privacy" className="footerLink hover:underline text-xs text-yellow-500 font-regular tracking-tight text-center transition duration-500 ease-in-out outline-none focus:outline-none ring-0 focus:ring-0 mr-2">
            Privacy Policy
          </a>
          <a href="/terms" className="ml-2 footerLink hover:underline text-yellow-500 text-xs font-regular tracking-tight text-center transition duration-500 ease-in-out outline-none focus:outline-none ring-0 focus:ring-0 mr-2">
            Terms and Conditions
          </a>
        </div>
        <div className="flex p-2 justify-center items-center text-center">
          <a href={`mailto:${envConfig.ContactEmail}`} className="footerLink hover:underline text-xs font-regular tracking-tight text-center transition duration-500 ease-in-out outline-none focus:outline-none ring-0 focus:ring-0 mr-2">{`${envConfig.ContactEmail}`}</a>
          <a href={`tel:${envConfig.ContactPhone}`} className="footerLink hover:underline text-xs font-regular tracking-tight</a> text-center transition duration-500 ease-in-out outline-none focus:outline-none ring-0 focus:ring-0 mr-2">
            <i className="fas fa-mobile-alt mr-1 footer-text"></i>{`${formatPhoneNumber(envConfig.ContactPhone)}`}
          </a>
        </div>
        <div className="flex p-2 justify-center items-center text-center">
          <a href="https://www.google.com/maps/place/Fit+Boxing/@18.4488069,-66.0817668,64m/data=!3m1!1e3!4m15!1m8!3m7!1s0x8c036f35b4f9b9b1:0xf84476e71edbcafc!2sAv.+Las+Palmas,+San+Juan!3b1!8m2!3d18.4453606!4d-66.0745313!16s%2Fg%2F1tcv7b_c!3m5!1s0x8c036f34c4b4b1ef:0x3bc2783b3a8989e1!8m2!3d18.4488209!4d-66.0816665!16s%2Fg%2F11b86jyzfc?entry=ttu&g_ep=EgoyMDI1MDEwNy4wIKXMDSoASAFQAw%3D%3D" 
             className="underline text-xs text-yellow-500 font-regular tracking-tight text-center transition duration-500 ease-in-out outline-none focus:outline-none ring-0 focus:ring-0 mr-2" 
             target="_blank" 
             rel="noopener noreferrer">
            <i className="fas fa-map-marker-alt mr-1 footer-text"></i>956 Av. Las Palmas, San Juan, PR 00909
          </a>
        </div> 
        <div>
        </div>
      </div>
    </Transition>
  );
}

export default Footer;

import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { LanguageIcon } from '@heroicons/react/24/outline'
import { Link, useNavigate, useParams, NavLink } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import {
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
  useResetRecoilState
} from 'recoil';
import {
  menuAtom,
  orderAtom,
  userAtom,
  paymentAtom,
  sidebarAtom,
  storeAtom,
  tokenAtom,
  serviceAtom,
} from '../../../atoms/Atoms';
import { businessesSelector, checkoutSelector } from '../../../atoms/Selectors';
import User from '../../../components/shared-components/user/User';
import envConfig from '../../../envConfig';
import './shared-resources/header.css';
import '../../../assets/css/custom.css';
import { formatPhoneNumber } from '../../../functions';



function HeaderV10(props) {
  const languages = [    
    {
      key: 'en',
      lang: 'en',
      name: 'English',
    },
    {
      key: 'es',
      lang: 'es',
      name: 'Español',
    },
  ];
  const { i18n, t } = useTranslation();
  const refreshStore = useResetRecoilState(businessesSelector);
  const { id } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useRecoilState(userAtom);
  const setToken = useSetRecoilState(tokenAtom);
  const setPayment = useSetRecoilState(paymentAtom);
  const order = useRecoilValue(orderAtom);
  const setSidebar = useSetRecoilState(sidebarAtom);
  const checkout = useRecoilValue(checkoutSelector);
  const store = useRecoilValue(storeAtom);
  const [menu, setMenu] = useRecoilState(menuAtom);
  const { location } = props;
  const [service, setService] = useRecoilState(serviceAtom);
  const profile = JSON.parse(localStorage.getItem(`${envConfig.StorageKey}-user`));
  const isAdmin = profile && (profile.roles.includes('Admin') || profile.roles.includes('SuperAdmin'));
  const [loading, setLoading] = useState(false);
  const [userSubscriptions, setUserSubscriptions] = useState([]);




return ( 
    <header className="headerStyle">
      <nav className="flex z-50 flex-shrink-0 bg-red-500 text-white items-center justify-between px-4 h-0 text-sm font-semibold">
        <div className="flex items-center">
          {!user && (
            <div className="md:flex space-x-2 pl-4">
              <NavLink
                to="/login"
                className="highlightText outline-none focus:outline-none ring-0 focus:ring-0 transition-colors duration-500 ease-linear"
              >
                {t('login')}
              </NavLink>
              <span className="mx-1">|</span>
               <NavLink
                to="/register"
                className="highlightText outline-none focus:outline-none ring-0 focus:ring-0 transition-colors duration-500 ease-linear"
              >
                {t('register')}
              </NavLink>
            </div>
          )}
          {user && (
            <Menu className="fontDefaults text-black md:block">
              {({ open }) => (
                <div className="relative">
                  <Menu.Button className="text-right flex md:space-x-1 hover:scale-90 transform items-center outline-none p-0 md:p-2 rounded-md focus:outline-none transition duration-500 ease-linear">
                    <User />
                  </Menu.Button>
                  {open && (
                    <Menu.Items
                      static
                      className="absolute z-50 right-0 mt-2 h-auto w-56 no-scrollbar overflow-auto bg-white divide-y divide-gray-100 rounded-xl shadow-lg outline-none focus:outline-none"
                    >
                       
                      <div className="fontDefaults userStyle flex text-xs md:text-sm justify-center bg-red-600">
                        <div className="m-2">
                          <div className="font-semibold tracking-tight truncate">
                            {user.completeName}
                          </div>
                          <div className="font-medium text-md tracking-tight truncate">
                            {user?.email}
                          </div>
                          <div className="font-medium text-md tracking-tight truncate">
                            {formatPhoneNumber(user?.phoneNumber)}
                          </div>
                          <div className="font-light tracking-tight truncate text-md">Version 2025.2</div>
                        </div>
                        {/* <Avatar /> */}
                      </div>
                      <Menu.Item
                        as="button"
                        type="button"
                        hidden={!isAdmin}
                        className="p-4 w-full text-left font-medium text-xs tracking-tight bg-yellow-200 hover:bg-yellow-500 outline-none focus:outline-none transition duration-500 easy-in-out"
                        onClick={() => {
                          navigate('/admin/attendance');
                        }}
                      >
                        {t('attendance')}
                      </Menu.Item>
                      <Menu.Item
                        as="button"
                        type="button"
                        className="book-now p-4 w-full text-left text-xs tracking-tight bg-red-400 hover:bg-red-500 outline-none focus:outline-none transition duration-500 easy-in-out"
                        onClick={() => {
                          navigate('/timeslots');
                        }}
                      >
                        {t('book_now')}
                      </Menu.Item>
                      <Menu.Item
                        as="button"
                        type="button"
                        className="p-4 w-full text-left font-medium text-xs tracking-tight hover:bg-gray-100 outline-none focus:outline-none transition duration-500 easy-in-out"
                        onClick={() => {
                          navigate('/profile/reservations');
                        }}
                      >
                        {t('reservations')}
                      </Menu.Item>
                      <Menu.Item
                        as="button"
                        type="button"
                        className="p-4 w-full text-left font-medium text-xs 
                        tracking-tight hover:bg-gray-100 outline-none 
                        focus:outline-none transition duration-500 easy-in-out"
                        onClick={() => {
                          navigate('/profile/subscriptions');
                        }}
                      >
                        {t('my_subscriptions')}
                      </Menu.Item>
                      <Menu.Item
                        as="button"
                        type="button"
                        className="p-4 w-full text-left font-medium text-xs tracking-tight 
                        hover:bg-gray-100 outline-none focus:outline-none transition
                         duration-500 easy-in-out"
                        onClick={() => {
                          navigate('/profile/payments');
                        }}
                      >
                        {t('payment_methods')}
                      </Menu.Item>
                      {/* <Menu.Item
                        as="button"
                        type="button"
                        className="p-4 w-full text-left font-medium text-xs tracking-tight
                        hover:bg-gray-100 outline-none focus:outline-none transition
                        duration-500 easy-in-out"
                        onClick={() => {
                          navigate.push('/profile/locations');
                        }}
                      >
                        {t('locations')}
                      </Menu.Item> */}
                      <Menu.Item
                        as="button"
                        type="button"
                        className="p-4 w-full text-left font-medium text-xs tracking-tight hover:bg-gray-100 outline-none focus:outline-none transition duration-500 easy-in-out"
                        onClick={() => {
                          navigate('/profile/settings');
                        }}
                      >
                        {t('my_profile')}
                      </Menu.Item>
                      {/* <Menu.Item
                        as="button"
                        type="button"
                        className="p-4 w-full text-left font-medium text-xs tracking-tight hover:bg-gray-100 outline-none focus:outline-none transition duration-500 easy-in-out"
                        onClick={() => {
                          navigate('/myprofile');
                        }}
                      >
                        {t('profile')}
                      </Menu.Item> */}
                      <Menu.Item
                        as="button"
                        type="button"
                        className="p-4 w-full text-left font-medium text-xs tracking-tight
                        hover:bg-gray-100 outline-none focus:outline-none transition
                        duration-500 easy-in-out"
                        onClick={() => {
                          navigate('/profile/password');
                        }}
                      >
                        {t('change_password')}
                      </Menu.Item>
                      <Menu.Item
                        as="button"
                        type="button"
                        className="text-white font-semibold p-4 w-full text-left text-xs tracking-tight bg-red-600 hover:bg-gray-700 outline-none focus:outline-none transition duration-500 easy-in-out"
                        onClick={() => {
                          setUser(null);
                          setToken(null);
                          setPayment({
                            card: null,
                            cards: null,
                            loading: true,
                            error: null,
                            options: null,
                          });
                          refreshStore();
                          window.localStorage.removeItem(`${envConfig.StorageKey}-user`);
                          window.localStorage.removeItem(`${envConfig.StorageKey}-card`);
                          window.localStorage.removeItem(`${envConfig.StorageKey}-token`);
                          navigate('/login');
                        }}
                      >
                        {t('logout')}
                      </Menu.Item>
                    </Menu.Items>
                  )}
                </div>
              )}
            </Menu>
          )}
          <Menu as="div" className="hidden md:hidden relative ml-2 text-3xl">
            <div className="relative flex ml-4">
              <Menu.Button className="hover:scale-90 transform group flex items-center leading-none ring-0 focus:ring-0 outline-none focus:outline-none transition duration-500 ease-in-out">
              <LanguageIcon className="h-6 w-6 text-accent" aria-hidden="true" />
              </Menu.Button>
            </div>
            <Transition
              as={React.Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Menu.Items className="absolute z-20 right-0 mt-2 w-32 ring-0 focus:ring-0 outline-none focus:outline-none">
                <div className="rounded-lg shadow-lg overflow-hidden">
                  <div className="relative bg-white p-4 space-y-4 text-xs">
                    {_.map(languages, (lang) => (
                      <Menu.Item
                        as="button"
                        key={lang.key}
                        type="button"
                        onClick={() => {
                          i18n.changeLanguage(lang.lang).then(() => {
                            window.localStorage.setItem(
                              `${envConfig.StorageKey}-lang`,
                              JSON.stringify(lang.lang)
                            );
                          });
                        }}
                        className={classNames(
                          i18n.language !== lang.lang && 'text-gray-400',
                          'group flex items-center hover:text-gray-500 space-x-2 ring-0 focus:ring-0 outline-none focus:outline-none transition ease-in-out duration-500'
                        )}
                      >
                          <LanguageIcon className="w-4 h-4 text-2xl" aria-hidden="true" />
                        <span>{lang.name}</span>
                      </Menu.Item>
                    ))}
                  </div>
                </div>
              </Menu.Items>
            </Transition>
          </Menu> 
        </div>
      </nav>

    </header>
  )
  } 
  

export default HeaderV10;

import React, { useState } from 'react';
import { useFormik, Field, ErrorMessage } from 'formik';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import '../shared-components/register.css';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import {
  envAtom,
  notificationsAtom,
  tokenAtom,
  stateAtom,
  userAtom,
  isLoggingInAtom,
} from '../../../../atoms/Atoms';
import Form from '../../../shared-components/form/Form';
import Input from '../../../shared-components/form/Input';
import CleaveInput from '../../../shared-components/form/CleaveInput';
import Select from '../../../shared-components/form/Select';
import Button from '../../../shared-components/buttons/Button';
import bookingApi from '../../../../api/bookingApi';
import envConfig from '../../../../envConfig';
import { Modal } from 'antd';
import api from '../../../../api/api';

function RegisterForm({ referral }) {
  const { i18n, t } = useTranslation();
  const env = useRecoilValue(envAtom);
  const navigate = useNavigate();
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const setIsLoggingIn = useSetRecoilState(isLoggingInAtom);
  const setUser = useSetRecoilState(userAtom);
  const setToken = useSetRecoilState(tokenAtom);
  const setState = useSetRecoilState(stateAtom);
  const [isCheckedAtt, setIsCheckedAtt] = useState(false);
  const [isCheckedTos, setIsCheckedTos] = useState(false);
  const [isCheckedMkt, setIsCheckedMkt] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const openTermsModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  function handleCheckboxChangeAtt() {
    setIsCheckedAtt(!isCheckedAtt);
  }

  
  function handleCheckboxChangeTos() {
    setIsCheckedTos(!isCheckedTos);
  }

  function handleCheckboxChangeTosAccept() {
    setIsCheckedTos(true);
    setIsModalVisible(false);
  }

  function handleCheckboxChangeMkt() {
    setIsCheckedMkt(!isCheckedMkt);
  }

  const formik = useFormik({
    initialValues: {
      fname: '',
      lname: '',
      email: '',
      phone: '',
      gender: 5,
      language: 0,
      password: '',
      passwordConfirmation: '',
      friendCode: referral,
      birthdate: '',
    },
    validationSchema: Yup.object().shape({
      fname: Yup.string().matches(/^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-ž\s\-\/.]+$/, t('name_must_be_valid')).required(`${t('required_field')}`),
      lname: Yup.string().matches(/^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-ž\s\-\/.]+$/, t('name_must_be_valid')).required(`${t('required_field')}`),
      email: Yup.string()
        .email(t('valid_email_validation'))
        .test('is-email', t('valid_email_validation'), (value) => /\S+@\S+\.\S+/.test(value)
        )
        .required(`${t('required_field')}`),
      phone: Yup.string().required(`${t('required_field')}`),
      password: Yup.string().min(4, 'Too Short').required(`${t('required_field')}`),
      passwordConfirmation: Yup.string()
        .oneOf(
          [Yup.ref('password'), null],
          `${t('same_password')}`
        )
        .required(`${t('required_field')}`),
    }),
    onSubmit: (values, { setSubmitting }) => {
        setIsLoggingIn(true);
      const payload = {
        Email: values.email,
        FirstName: values.fname,
        Gender: parseInt(values.gender, 10),
        LastName: values.lname,
        Password: values.password,
        PhoneNumber: values.phone,
        Birthdate: new Date(),
        PreferredLanguage: parseInt(values.language, 10),
        FriendCode: values.friendCode,
        AllowMarketing: isCheckedMkt,
      };
      api.post('oauth/local/register', payload)
        .then((response) => {
          if (response.data.error) {
            setNotifications([
              ...notifications,
              {
                title: `${t('oops')}`,
                description:
                `${t('registration_error')}`,
                error: true,
              },
            ]);
          } else {
            setNotifications([
              ...notifications,
              {
                title: `${t('yay')}`,
                description:
                `${t('registration_success')}`,
                error: false,
              },
            ]);
            localStorage.setItem(
              `${envConfig.StorageKey}-state`,
              JSON.stringify(response.data.data.state)
            );
            localStorage.setItem(
              `${envConfig.StorageKey}-user`,
              JSON.stringify(response.data.data.state.profile)
            );
            localStorage.setItem(
              `${envConfig.StorageKey}-token`,
              JSON.stringify(response.data.data.accessToken)
            );
            formik.resetForm();
            setState(response.data.data.state);
            setUser(response.data.data.state.profile);
            setToken(response.data.data.accessToken);
            navigate('/');
          }
          setSubmitting(false);
          setIsLoggingIn(false);
        })
        .catch((error) => {
          setNotifications({ ...notifications, signUpError: error.message });
          setNotifications([
            ...notifications,
            {
              title: `${t('oops')}`,
              description: `${t('registration_error')} - ${error.message}`,
              error: true,
            },
          ]);
          setSubmitting(false);
          setIsLoggingIn(false);
        });
    },
  });

  return (
    <>
      <Form>
        <Input
          label={t('first_name')}
          required
          type="text"
          name="fname"
          id="fname"
          placeholder={t('sample_first_name')}
          autoComplete="given-name"
          autoCapitalize="true"
          value={formik.values.fname}
          onChange={formik.handleChange}
          error={
            formik.errors.fname && formik.touched.fname && formik.errors.fname
          }
        />
        <Input
          label={t('last_name')}
          required
          type="text"
          name="lname"
          id="lname"
          autoComplete="family-name"
          placeholder={t('sample_last_name')}
          autoCapitalize="true"
          value={formik.values.lname}
          onChange={formik.handleChange}
          error={
            formik.errors.lname && formik.touched.lname && formik.errors.lname
          }
        />
        <Input
          label={t('email')}
          required
          type="email"
          name="email"
          id="email"
          placeholder={t('sample_email')}
          autoComplete="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={
            formik.errors.email && formik.touched.email && formik.errors.email
          }
        />
        <div className="md:flex md:flex-col w-full text-xs tracking-tight space-y-1">   
        <Select
          className="text-xs"
          label={t('language')}
          id="language"
          name="language"
          value={formik.values.language}
          onChange={formik.handleChange}
          options={[
            {
              key: '0',
              value: 0,
              text: `${t('english')}`,
            },
            {
              key: '1',
              value: 1,
              text: `${t('spanish')}`,
            },
          ]}
        />  
        </div> 
        <CleaveInput
          label={t('phone')}
          required
          id="phone"
          name="phone"
          type="text"
          autoComplete="tel"
          options={{
            phone: true,
            phoneRegionCode: 'PR',
          }}
          value={formik.values.phone}
          onChange={(e) => {
            formik.setFieldValue('phone', e.target.rawValue);
          }}
          placeholder="787-555-5555"
          error={
            formik.errors.phone && formik.touched.phone && formik.errors.phone
          }
        />
        <Select
          className="text-xs"
          label={t('gender')}
          id="gender"
          name="gender"
          value={formik.values.gender}
          onChange={formik.handleChange}
          options={[
            {
              key: '5',
              value: 5,
              text: `${t('select_gender')}`,
              disabled: true,
            },
            {
              key: '0',
              value: 0,
              text: `${t('male')}`,
            },
            {
              key: '1',
              value: 1,
              text: `${t('female')}`,
            },
            {
              key: '2',
              value: 2,
              text: `${t('unknown_gender')}`,
            },
          ]}
        />       
        <Input
          label={t('password')}
          required
          id="password"
          name="password"
          type="password"
          autoComplete="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          placeholder="***"
          error={
            formik.errors.password &&
            formik.touched.password &&
            formik.errors.password
          }
        />
        <Input
          label={t('confirm_password')}
          required
          id="passwordConfirmation"
          name="passwordConfirmation"
          type="password"
          autoComplete="password"
          value={formik.values.passwordConfirmation}
          onChange={formik.handleChange}
          placeholder="***"
          error={
            formik.errors.passwordConfirmation &&
            formik.touched.passwordConfirmation &&
            formik.errors.passwordConfirmation
          }
        />
        <Input
          label={`${t('referral_code')} (${t('optional')})`}
          type="text"
          name="friendCode"
          id="friendCode"
          disabled={referral}
          className={`${envConfig.HasReferrals === 'true' || referral ? '' : 'hidden'}`}
          placeholder="xxxx-xxxx"
          value={formik.values.friendCode}
          onChange={formik.handleChange}
        />
      </Form>
      <div className="flex items-center mx-1 mt-2 mb-4">
        <input
          key="tos_agreement"
          type="checkbox"
          className="h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
          checked={isCheckedTos}
          onChange={handleCheckboxChangeTos}
        />
        <label htmlFor="agreement" className="ml-4 text-xs">
          {t('tos_agreement')}{' '}
          <span
            className="text-blue-500 cursor-pointer underline"
            onClick={openTermsModal}
          >
            {t('terms_and_conditions')}
          </span>
        </label>
      </div>
      <Modal
        title={t('terms_conditions')}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleOk}
        footer={<button className="acceptTermsButton" onClick={handleCheckboxChangeTosAccept}>{t('I Accept')}</button>} 
        width={500}
        style={{ maxHeight: '50vh', overflow: 'auto' }}
      >
        <div>
          <p>{t('tos_content_1')}</p>
          <p>{t('tos_content_2')}</p>
          <p>{t('tos_content_3')}</p>
          <p>{t('tos_content_4')}</p>
          <p>{t('tos_content_5')}</p>
          <p>{t('tos_content_7')}</p>
        </div>
      </Modal>

      <div className="flex items-center mx-1 mt-2 mb-4">
        <input
          key="agreement"
          type="checkbox"
          className="h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
          checked={isCheckedAtt}
          onChange={handleCheckboxChangeAtt}
        />
        <label htmlFor="agreement" className="ml-4 text-xs">
          {t('profile_creation_attestation')}
        </label>
      </div>  
      <div className="hidden items-center mx-1 mt-2 mb-4 ">
        <input
        id="marketingoptin"
          type="checkbox"
          className="h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
          checked={isCheckedMkt}
          onChange={handleCheckboxChangeMkt}
        />
        <label htmlFor="marketingoptin" className="ml-4 text-xs">
          {t('profile_creation_opt_in')}
        </label>
      </div>  
          <div className="flex flex-row">
      <Button
        text={t('cancel')}
        textColor="text-gray-200"
        onClick={(e) => {
          e.preventDefault();
          navigate('/');
        }}
        className="bg-red-500 hover:bg-red-600 w-20 mr-2"
      />
      <Button
        text={t('register')}
        textColor="text-gray-300"
        disabled={formik.isSubmitting || !isCheckedAtt || !isCheckedTos }
        loading={formik.isSubmitting}
        onClick={formik.submitForm}
        loaderColor="bg-white"
        className="bg-yellow-600 hover:bg-yellow-700 w-20 ml-4"
      />
      </div>
    </>
  );
}

export default RegisterForm;

export const phoneNumber = (phone) => {
    const cleaned = `${phone}`.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return null;
  };
  
  // Desktop animation variants for backdrop
  export const backdropVariants = {
    visible: {
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
    hidden: {
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
  };
  
  // animation variants for details-modal
  export const modalVariants = {
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 1,
        type: 'spring',
      },
    },
    hidden: {
      opacity: 0,
      scale: 0,
      transition: {
        duration: 1,
        type: 'spring',
      },
    },
  };
  
  // animation variants for sidebar
  export const sidebarVariants = {
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.5,
      },
    },
    hidden: {
      opacity: 0,
      x: '100%',
      transition: {
        duration: 0.5,
        type: 'spring',
      },
    },
  };
  
  // animation variants for product list
  export const containerVariants = {
    open: {
      transition: { staggerChildren: 0.07, delayChildren: 0.2, delay: 1 },
    },
    closed: {
      transition: { staggerChildren: 0.05, staggerDirection: -1, delay: 1 },
    },
  };
  
  export const productVariants = {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 },
      },
    },
    closed: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 },
      },
    },
  };
  
  export const PlaceToPayLogo = 'https://static.placetopay.com/placetopay-logo-square-dark-background.svg';
  // export const PlaceToPayLogo = 'https://static.placetopay.com/placetopay-logo-dark-background.svg;'

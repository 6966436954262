import React, { useRef, useState, useEffect } from "react";
import './shared-components/attendance.css';
import QRCode from "react-qr-code";
import { motion, AnimatePresence } from 'framer-motion';
import _, { times } from 'lodash';
import moment from "moment";
import { useTranslation } from "react-i18next";
import PaymentDrawer from "../../shared-components/drawers/PaymentDrawer";
import Modal from "../../shared-components/modal/Modal";
import { useMount, useClickAway } from "react-use";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import bookingApi from '../../../api/bookingApi';
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import { refreshTimeSlotsAtom } from "../../../atoms/Atoms";
import Loader from "../../shared-components/loader/Loader";
import Button from "../../shared-components/buttons/Button";
import { backdropVariants, modalVariants } from '../../../utils';


function Attendance() {
    const { t, i18n } = useTranslation();
    const [locations, setLocations] = useState([]);
    const [loading, setLoading] = useState(false);
    const [reload, setReload] = useState(false);
    const refreshTimeSlots = useRecoilValue(refreshTimeSlotsAtom);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
    const [timeSlotDays, setTimeSlotDays] = useState([]);
    const [endDate, setEndDate] = useState(null);
    const [selectedButtonIndex, setSelectedButtonIndex] = useState(null);
    const [dateSelected, setDateSelected] = useState(false);
    const [bookings, setBookings] = useState([]);
    const [showQrCode, setShowQrCode] = useState(false);
    const startDate = useState(moment().utc().add(-4,'hour').format('YYYY-MM-DDTHH:00:00.00'));
    // const paymentsRef = useRef();
    const endDateOptions = [
      { title: `4 ${t('days')}`, date: moment().utc().add(4, 'day').format('YYYY-MM-DDTHH:00:00.00') },
      { title: t('today'), date: moment().utc().add(1, 'day').format('YYYY-MM-DDTHH:00:00.00') },
      { title: `3 ${t('days')}`, date: moment().utc().add(3, 'day').format('YYYY-MM-DDTHH:00:00.00') },
      { title: `5 ${t('days')}`, date: moment().utc().add(5, 'day').format('YYYY-MM-DDTHH:00:00.00') },
      { title: `7 ${t('days')}`, date: moment().utc().add(7, 'day').format('YYYY-MM-DDTHH:00:00.00') },
    ]

    const ref = useRef();
    const closeModal = () => {
      setShowQrCode(false);
    };

    const openDrawer = () => {
      setIsDrawerOpen(true);
    };
  
    const closeDrawer = () => {
      setIsDrawerOpen(false);
    };

    const handleButtonClick = (i) => {
      setDateSelected(true);
    };

    //type 0=Rental; 1=Class
    function getLocations() {
      setLoading(true);
      bookingApi
        .get('events/locations')
          .then((response) => {
            setLoading(false);
            setLocations(_.sortBy(response.data.data, 'name'));
            // if (response.data.data.length === 1) {
            //   setSelectedLocation(response.data.data[0]);
            // }
          })
          .catch((error) => {
            setLoading(false);
            console.error(error);
          });
    }

    function getBookings() {
        setLoading(true);
        bookingApi
          .get(`events/timeSlots/${selectedTimeSlot?.id}/bookings`)
          .then((response) => {
            setLoading(false);
            setBookings(_.filter(response.data.data, (b) => b.status  !== 2));
          })
          .catch((error) => {
            setLoading(false);
            // showMessage(error.message);
            console.error(error.message);
          });
      }

    function searchTimeSlots() {
      if (!selectedLocation || !endDate) {
        return;
      }
      setTimeSlotDays([]);
      setLoading(true);
      bookingApi
        .post('admin/attendance/timeslots/day', {
          searchTerm: null,
          type: null,
          hostId: null,
          locationId: selectedLocation.id,
          endDate: endDate?.date,
          startDate: startDate[0]
        })
          .then((response) => {
            setLoading(false);
            const tempDays = _.sortBy(response.data.data, 'startDate');
            setTimeSlotDays(tempDays);
          })
          .catch((error) => {
            setLoading(false);
            console.error(error);
          });
    }

    function getSpacesLeft (timeSlot)
    {
      return timeSlot.maxCapacity - timeSlot.currentCapacity; 
    }

    function getStatus (status)
    {
      if(status === 0)
        return 'Pending'; 
      else if(status === 1)
        return 'Confirmed'; 
      else if(status === 2)
        return 'Cancelled'; 
    }

    useEffect(() => {
        if (selectedLocation && selectedDate) {
            getBookings();
        }
      }, [selectedTimeSlot]);

      useEffect(() => {
        if (selectedLocation && selectedDate) {
          searchTimeSlots();
          setBookings([]);
        }
      }, [reload]);

    // console.log(timeSlotDays);
  
    useMount(() => {
      setEndDate(endDateOptions[4]);
      getLocations();
    }, []);

    useEffect(() => {
      if (selectedLocation) {
        searchTimeSlots();
        setSelectedDate(null);
        setBookings([]);
        setSelectedTimeSlot(null);
      }
    }, [selectedLocation, endDate]);

    useEffect(() => {
      searchTimeSlots();
    }, [refreshTimeSlots]);

    useClickAway(ref, () => {
      closeModal();
    });

    console.log(timeSlotDays);
    
    const buttonVariants = {
        initial: { y: 1000, opacity: 0 }, // Initial position (from the right) and invisible
        animate: { y: 0, opacity: 1 }, // Animate to the original position and visible
      };
      const hourVariants = {
        initial: { x: 1000, opacity: 0 }, // Initial position (from the right) and invisible
        animate: { x: 0, opacity: 1 }, // Animate to the original position and visible
        exit: {x: 1000, opacity: 0}
      };
      const sideVariants = {
        initial: { x: 1000, opacity: 0 }, // Initial position (from the right) and invisible
        animate: { x: 0, opacity: 1 }, // Animate to the original position and visible
        exit: {x: 1000, opacity: 0}
      };
    return <div className="body overflow-auto">
        <div className="background-image"></div>
        <div className="gradient-overlay"></div> {/* Add the gradient overlay */}
          <PaymentDrawer isOpen={isDrawerOpen} selectedDate={selectedDate} selectedTimeSlot={selectedTimeSlot} closeDrawer={closeDrawer} />
           {/* Use the Modal component when the drawer is open */}
         <Modal isOpen={isDrawerOpen} closeModal={closeDrawer}>
          {/* Place the content of the modal here */}
          <h2>Modal Content</h2>
          {/* Add any other content or components you want */}
          </Modal>
      {/* ... (your existing code) */}
        <div key="Attendance">     
          <AnimatePresence key="attendence">      
            <div key="center" className="center">
                <div className="messageContainer">
                    <div className="diheaderText">{t('attendance')}</div>
                    {locations && locations.length > 0 &&
                      <Button
                        text={t('reload')}
                        textColor="text-white"
                        disabled={loading || !selectedLocation || !selectedDate || !selectedTimeSlot}
                        loading={loading}
                        onClick={() => {
                            setReload(!reload); 
                        }}
                        loaderColor="bg-white"
                        className="bg-green-600 hover:bg-green-300 w-20 mr-2"
                        />}
                      {locations && !loading &&  locations.length === 0 && <div className="warningText">There are no active clases at the moment</div>}
                      <div className="mb-2 text-left ml-8 mt-4 hidden md:block">{locations && !loading && locations.length > 0 && 'Choose a location'}</div>
                      {locations && _.map(locations, (loc, index) => (
                      <div className="hidden md:block">
                      <motion.button
                        key={`${index}`}
                        initial="initial" // Use the "initial" variant
                        animate="animate" // Use the "animate" variant
                        variants={buttonVariants} // Define the animation variants
                        transition={{ delay: index * 0.2 }} // Apply a delay based on the index
                        onClick={() => 
                          setSelectedLocation(loc)}
                        className={`${selectedLocation && (selectedLocation.id=== loc.id) ? 'border-1 ring-1 ring-offset-1 ring-white border-white' : null} orange-button`}
                    >   {loc.nameEs}
                    </motion.button>
                  </div>))}
                  <div className="mb-2 text-left ml-8 mt-4 hidden md:block">
                    <div>
                      {!loading && timeSlotDays && selectedLocation && !loading && 'Choose a day'}
                      {loading && <Loader />}
                    </div>
                  </div>
                  <div>              
                    {_.map(timeSlotDays, (day, index) => (
                   <div className="hidden md:block" key={index}>
                    <motion.button
                        key={`${index}-button`}
                        initial="initial" // Use the "initial" variant
                        animate="animate" // Use the "animate" variant
                        variants={buttonVariants} // Define the animation variants
                        transition={{ delay: index * 0.2 }} // Apply a delay based on the index
                        className={`${selectedDate && (selectedButtonIndex === index) ? 'border-1 ring-2 ring-offset-1 ring-white border-white' : null} blue-button`}
                        onClick={() => 
                          { 
                            setSelectedDate(null);
                            setBookings([]);
                            setSelectedDate(timeSlotDays[index]);
                            setSelectedButtonIndex(index);
                            setDateSelected(day);
                          }
                        }
                    >   <div>                        
                          <div>{moment(day?.startDate).format('dddd, MMMM DD, YYYY')}</div>
                        </div>
                    </motion.button> 
                  </div>))} 
                  </div>
                </div>
                <div className="listsContainer">
                <div className="flex flex-col md:hidden">
                  <div className="mb-2 w-full text-center">{locations && 'Choose a location'}</div>
                  {/* <div className="mb-2 w-full text-center">{locations && timeSlotDays && selectedLocation && selectedLocation.description}</div> */}
                  {locations && _.map(locations, (loc, index) => (
                   <div>
                      <motion.button
                        key={`${index}`}
                        initial="initial" // Use the "initial" variant
                        animate="animate" // Use the "animate" variant
                        variants={buttonVariants} // Define the animation variants
                        transition={{ delay: index * 0.2 }} // Apply a delay based on the index
                        onClick={() => 
                          setSelectedLocation(loc)}
                        className={`${selectedLocation && (selectedLocation.id=== loc.id) ? 'border-1 ring-1 ring-offset-1 ring-white border-white' : null} red-button`}
                    >   {loc.nameEs}
                    </motion.button>
                  </div>))}
                    <div className="mb-2 w-full text-center">{timeSlotDays && selectedLocation && !loading && 'Choose a day'}</div>
                    <div>              
                    {_.map(timeSlotDays, (day, index) => (
                   <div key={index}>
                    <motion.button
                        key={`${index}-button`}
                        initial="initial" // Use the "initial" variant
                        animate="animate" // Use the "animate" variant
                        variants={buttonVariants} // Define the animation variants
                        transition={{ delay: index * 0.2 }} // Apply a delay based on the index
                        className={`${selectedDate && (selectedButtonIndex === index) ? 'border-1 ring-2 ring-offset-1 ring-white border-white' : null} blue-button`}
                        onClick={() => 
                          { 
                            setSelectedDate(null);
                            setBookings([]);
                            setSelectedDate(timeSlotDays[index]);
                            setSelectedButtonIndex(index);
                            setDateSelected(day);
                          }
                        }
                    >   {moment(day?.startDate).format('dddd, MMMM DD, YYYY')}
                    </motion.button>
                    {selectedButtonIndex === index && selectedDate && _.map(_.sortBy(selectedDate.timeSlots, 'startTime'), (timeSlot, tsIndex) => (
                    <div className="flex flex-col">
                    <motion.button
                          key={`${tsIndex}-inner`}
                          initial="initial" // Use the "initial" variant
                          animate="animate" // Use the "animate" variant
                          exit="exit"
                          variants={hourVariants} // Define the animation variants
                          transition={{ delay: tsIndex * 0.1 }} // Apply a delay based on the index
                          className={`${!timeSlot.isFull ? 'second-button' :  'full-class'} px-2 block'`}
                          onClick={ () => {
                            if(!selectedTimeSlot || (timeSlot && timeSlot.id !== selectedTimeSlot.id)) {
                              setBookings([]);
                              setSelectedTimeSlot(timeSlot);
                              // getBookings();
                            }
                            else if(selectedTimeSlot && (timeSlot && timeSlot.id === selectedTimeSlot.id)) {
                              setBookings([]);
                              getBookings();
                            }
                          }}
                      >
                        <div className="flex flex-col h-auto text-xs" >
                        {timeSlot && timeSlot.isFull && <div className={timeSlot?.event.type !== 2 ? "font-semibold" : "hidden"}>
                        {`Full (${moment(timeSlot?.startTime).add(-4,'hour').format('h:mm A')})`}
                        </div>}
                        {timeSlot && timeSlot.isFull && <div className={timeSlot?.event.type === 2 ? "font-semibold flex flex-col" : "hidden"}>
                          <div>{`${timeSlot?.event.name}`}</div>
                          <div>{`Full (${moment(timeSlot?.startTime).add(-4,'hour').format('h:mm A')})`}</div>
                        </div>}
                        <div className={timeSlot?.event.type === 2 && !timeSlot.isFull  ? "text-xs font-semibold" : "hidden"}>
                          <div className={timeSlot?.event.type === 2 && !timeSlot.isFull  ? "text-xs font-semibold" : "hidden"}>{`${timeSlot?.event.name}`}</div>
                          <div className={timeSlot?.event.type === 2 && !timeSlot.isFull  ? "text-xs font-semibold" : "hidden"}>{`${moment(timeSlot?.startTime).add(-4,'hour').format('h:mm A')}`}</div>
                        </div>
                        <div className="text-xs font-bold">{`${timeSlot?.event.name}`}</div>
                        <div className="text-xs font-semibold">{`${timeSlot?.timeSlotHost?.completeName}`}</div>
                        <div className={timeSlot?.event.type !== 2 ? "font-semibold" : "hidden"}>{timeSlot && !timeSlot.isFull &&  `${moment(timeSlot?.startTime).add(-4,'hour').format('h:mm A')}`}</div>
                        <div className="">{timeSlot && !timeSlot.isFull &&  `Reservations:  ${timeSlot?.currentCapacity} / Available: ${getSpacesLeft(timeSlot)}`}</div>
                        <div className="">{timeSlot && timeSlot.isFull &&  `Reservations:  ${timeSlot?.currentCapacity}`}</div>
                        </div>
                    </motion.button>
                    {/* <div className="mx-2 mb-2 w-full text-center md:hidden block">{selectedTimeSlot && bookings && timeSlot?.id === selectedTimeSlot?.id && bookings.length > 0 && `Bookings (${bookings.length})`}</div> */}
                    {selectedTimeSlot && timeSlot.id === selectedTimeSlot.id && (
                        <motion.button
                            initial="initial" // Use the "initial" variant
                            animate="animate" // Use the "animate" variant
                            exit="exit"
                            variants={sideVariants} // Define the animation variants
                            transition={{ delay: 0 }} // Apply a delay based on the index
                            className="qrcode-button h-10 pl-4 md:hidden hidden"
                            onClick={() => setShowQrCode(true)}
                        >
                          {t('qr_code')}
                        </motion.button>
                      )}
                    {selectedTimeSlot && bookings && timeSlot.id === selectedTimeSlot.id && _.map(_.sortBy(bookings, 'createdAt'), (booking, bookingIndex) => (
                        <motion.button
                              key={`${bookingIndex}-booking`}
                              initial="initial" // Use the "initial" variant
                              animate="animate" // Use the "animate" variant
                              exit="exit"
                              variants={sideVariants} // Define the animation variants
                              transition={{ delay: bookingIndex * 0.1 }} // Apply a delay based on the index
                              className="third-button block text-left text-sm pl-4 w-5/6"
                          >
                            {bookings && 
                             <div className="ml-2">
                             <div className="attendants">
                             {`${bookingIndex + 1}. ${bookings[bookingIndex]?.firstName} ${bookings[bookingIndex]?.lastName}`}
                               <div className="hidden text-left">{`(${getStatus(bookings[bookingIndex]?.status)})`}</div>
                             </div></div>}
                          </motion.button>
                        ))}    
                        </div>             
                    ))}                    
                    </div>
                    ))}
                </div>              
                </div>  
                <div className="sideList md:flex flex-row hidden">
                  <div>
                  <div className="mx-2 mb-2 w-full text-center mt-24">{selectedDate && 'Choose Hour'}</div>
                    {selectedDate && _.map(_.sortBy(selectedDate.timeSlots, 'startTime'), (timeSlot, tsIndex) => (
                    <motion.button
                          key={`${tsIndex}-side`}
                          initial="initial" // Use the "initial" variant
                          animate="animate" // Use the "animate" variant
                          exit="exit"
                          variants={sideVariants} // Define the animation variants
                          transition={{ delay: tsIndex * 0.1 }} // Apply a delay based on the index
                          className={`${!timeSlot.isFull ? 'second-button' :  'full-class'} hidden md:block h-10 text-sm'`}
                          onClick={ () => {
                          if(!selectedTimeSlot || (timeSlot && timeSlot.id !== selectedTimeSlot.id)) {
                            setBookings([]);
                            setSelectedTimeSlot(timeSlot);
                            getBookings();
                          }
                          else if(selectedTimeSlot && (timeSlot && timeSlot.id === selectedTimeSlot.id)) {
                            setBookings([]);
                            getBookings();
                          }
                          }}
                      > <div className="flex flex-col h-auto text-xs" >
                        {timeSlot && timeSlot.isFull && <div className={timeSlot?.event.type !== 2 ? "font-semibold" : "hidden"}>
                        {`Full (${moment(timeSlot?.startTime).add(-4,'hour').format('h:mm A')})`}
                        </div>}
                        {timeSlot && timeSlot.isFull && <div className={timeSlot?.event.type === 2 ? "font-semibold" : "hidden"}>
                        {`${timeSlot?.event.name} - Full (${moment(timeSlot?.startTime).add(-4,'hour').format('h:mm A')})`}
                        </div>}
                        <div className="text-xs font-semibold">{`${timeSlot?.event.name}`}</div>
                        <div className="text-xs font-semibold">{`${timeSlot?.timeSlotHost?.completeName}`}</div>
                        <div className={timeSlot?.event.type === 2 && !timeSlot.isFull  ? "text-xs font-semibold" : "hidden"}>{`${timeSlot?.event.name} - ${moment(timeSlot?.startTime).add(-4,'hour').format('h:mm A')}`}</div>
                        <div className={timeSlot?.event.type !== 2 ? "font-semibold" : "hidden"}>{timeSlot && !timeSlot.isFull &&  `${moment(timeSlot?.startTime).add(-4,'hour').format('h:mm A')}`}</div>
                        <div className="">{timeSlot && !timeSlot.isFull &&  `Reservations:  ${timeSlot?.currentCapacity} / Available: ${getSpacesLeft(timeSlot)}`}</div>
                        <div className="">{timeSlot && timeSlot.isFull &&  `Reservations:  ${timeSlot?.currentCapacity}`}</div>
                        </div>
                    </motion.button>
                      
                    ))}</div>
                     <div className="bookingsList mt-24 ml-4">
                      {selectedTimeSlot && (
                        <motion.button
                            initial="initial" // Use the "initial" variant
                            animate="animate" // Use the "animate" variant
                            exit="exit"
                            variants={sideVariants} // Define the animation variants
                            transition={{ delay: 0 }} // Apply a delay based on the index
                            className="qrcode-button h-10 pl-4 hidden md:hidden"
                            onClick={() => setShowQrCode(true)}
                        >
                          {t('qr_code')}
                        </motion.button>
                      )}
                  <div className="mx-2 mb-2 w-full text-center md:block">{bookings && bookings.length > 0 && `Attendants (${bookings.length})`}</div>
                  {selectedTimeSlot && bookings && _.map(_.sortBy(bookings, 'createdAt'), (booking, bookingIndex) => (
                    <motion.button
                          key={`${bookingIndex}-booking`}
                          initial="initial" // Use the "initial" variant
                          animate="animate" // Use the "animate" variant
                          exit="exit"
                          variants={sideVariants} // Define the animation variants
                          transition={{ delay: bookingIndex * 0.1 }} // Apply a delay based on the index
                          className="third-button md:block h-10 pl-4"
                      >
                        {bookings && selectedDate && selectedLocation && 
                        <div className="ml-2">
                        <div className="text-left">
                        {`${bookingIndex + 1}. ${bookings[bookingIndex]?.firstName} ${bookings[bookingIndex]?.lastName}`}
                          <div className="hidden text-left ml-4">{`(${getStatus(bookings[bookingIndex]?.status)})`}</div>
                        </div></div>}
                      </motion.button>
                    ))}
              </div>   
              </div>                
              </div>     
            </div>
            <motion.div
              variants={backdropVariants}
              initial="hidden"
              animate="visible"
              exit="hidden"
              className={`${showQrCode ? 'fixed' : 'hidden'} bg-gray-800 inset-0 bg-opacity-75 z-50 flex items-end md:items-center justify-center px-0 md:px-4`}
            >
              <motion.div
                variants={modalVariants}
                className="text-gray-800 antialiased justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
              >
                <div
                  ref={ref}
                  className="bg-gray-50 rounded-xl mx-auto"
                >
                  <main className="p-2 bg-gray-50 rounded-xl">
                    <div className="md:p-4">
                      {/* <button
                        type="button"
                        onClick={() => closeModal()}
                        className="text-xl leading-none float-right ring-0 outline-none focus:outline-none"
                      >
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </button>
                      <br /> */}
                      <QRCode
                        className="m-2 md:m-4"
                        value={`${window.location.origin}/dropin/${selectedTimeSlot?.id}`}
                      />
                    </div>
                  </main>
                </div>
              </motion.div>
            </motion.div>
          </AnimatePresence>
        </div>
    </div>
}

export default Attendance;